import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getGoodsByKeysAction } from "../reducers/coupon";
import CouponProduct from "./CouponProduct";
import CustomSpinner from "./CustomSpinner";
import { getGoodsByGroup } from "../api/couponAPI";

export const ProductColumn = ({ record }) => {
  const jgroupKey = useSelector(
    (state) => state.groupReducer.jgroup_key_inCookie
  );
  const goods = useSelector((state) => state.couponReducer.goodsByGroup);
  const [pbClicked, setPbClicked] = useState(false);
  const [filteredGoods, setfilteredGoods] = useState([]);

  /** 쿠폰 수정 모달창을 여는 함수 */
  const openProductListModal = () => {
    document.body.style.overflow = "hidden";
    setPbClicked((pbClicked) => !pbClicked);
  };
  /** 쿠폰 수정 모달창을 여는 함수 */
  const closeProductListModal = () => {
    document.body.style.overflow = "unset";
    setPbClicked((pbClicked) => !pbClicked);
  };

  useEffect(() => {
    const filterGoodsByType = async () => {
      if (!goods?.length > 0) {
        return;
      }
      let goodsArr = [];
      switch (record.available_type) {
        case "LIMITED":
          if (record.available_products) {
            record.available_products.map((goodsKey) =>
              goods.filter(
                (product) =>
                  product.goods_key === goodsKey &&
                  product.market_type === "BRAND_MARKET" &&
                  (product.product_kind === "BRAND_TICKET_LESSON" ||
                    product.product_kind === "CRM_TICKET_LESSON") &&
                  goodsArr.push(product)
              )
            );
          }
          break;
        case "MARKET_TICKET_UNLIMITED":
          goodsArr = goods.filter(
            (product) =>
              product.market_type === "BRAND_MARKET" &&
              (product.product_kind === "BRAND_TICKET_LESSON" ||
                product.product_kind === "CRM_TICKET_LESSON")
          );
          break;
        case "PRIVATE_MARKET_TICKET_LIMITED":
          record?.available_products?.map((goodsKey) =>
            goods?.filter(
              (product) =>
                product.goods_key === goodsKey &&
                product.market_type === "PRIVATE_MARKET" &&
                (product.status === "ONGOING" ||
                  product.status === "WAITING") &&
                goodsArr.push(product)
            )
          );
          break;
        case "PRIVATE_MARKET_TICKET_UNLIMITED":
          goodsArr = goods.filter(
            (product) =>
              product.market_type === "PRIVATE_MARKET" &&
              (product.status === "ONGOING" || product.status === "WAITING")
          );
          break;

        default:
          break;
      }
      setfilteredGoods(goodsArr);
    };

    if (!pbClicked) {
      return;
    }
    filterGoodsByType();
  }, [pbClicked]);
  
  return (
    <CouponHistoryColumn>
      <ProductButton onClick={openProductListModal}>보기</ProductButton>
      {pbClicked && (
        <CouponProduct
          tableData={filteredGoods}
          handleChange={closeProductListModal}
        />
      )}
    </CouponHistoryColumn>
  );
};

const ProductButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  border-radius: 16px;
  font-size: 12px;
  line-height: 17px;
  background: #4a4a4a;
  color: #fff;
  height: 32px;
  width: 71px;
  :hover {
    cursor: pointer;
  }
`;

const CouponHistoryColumn = styled.div`
  font-family: "Noto Sans KR-Regular";
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
