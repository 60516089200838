import Layout, { Content, Header } from "antd/lib/layout/layout";
import React, { useEffect, useRef, useState } from "react";
import "antd/dist/antd.min.css";
import "../styles/globals.css";
import "../styles/Message.css";
import {
  ConfigProvider,
  DatePicker,
  Dropdown,
  Form,
  Menu,
  Spin,
  Tabs,
} from "antd";
import { Icon, Spacer, Text } from "../styles/commonStyle";
import styled from "styled-components";
import MessageTable from "../components/MessageTable";
import moment from "moment";
import { CustomButton } from "../components/CustomButton";
import MessageSearchBar from "../components/MessageSearchBar";
import { useDispatch, useSelector } from "react-redux";
import {
  getMessageHistoryAction,
  getPushNotificationHistoryAction,
} from "../reducers/message";
import _ from "lodash";
import CustomSpinner from "../components/CustomSpinner";
import locale from "antd/lib/locale/ko_KR";
import "moment/locale/ko";

moment.locale("ko");

const { RangePicker } = DatePicker;

const { TabPane } = Tabs;

const MenuItem = Menu.Item;

const menuStyle = {
  backgroundColor: "#4a4a4a",
};

const menuItemStyle = {
  background: "none",
  color: "#ffffff",
  fontSize: "12px",
};

const CustomMenu = ({ handleChange }) => {
  return (
    <Menu style={menuStyle} defaultSelectedKeys={1} onChange={handleChange}>
      <MenuItem key={"total"} style={menuItemStyle} onClick={handleChange}>
        전체 메세지 타입
      </MenuItem>
      <MenuItem key={"sms"} style={menuItemStyle} onClick={handleChange}>
        SMS
      </MenuItem>
      <MenuItem key={"lms"} style={menuItemStyle} onClick={handleChange}>
        LMS
      </MenuItem>
      {/* <MenuItem key={"mms"} style={menuItemStyle} onClick={handleChange}>
        MMS
      </MenuItem> */}
    </Menu>
  );
};

const MessageHistory = () => {
  const [tab, setTab] = useState("message");
  const [date, setDate] = useState(moment(new Date()));
  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(moment(new Date()).format("YYYYMM"));
  const [index, setIndex] = useState(0);
  const [filter, setFilter] = useState(null);
  const [pushFilter, setPushFilter] = useState(null);
  const [item, setItem] = useState("total");
  const [pushStartDttm, setPushStartDttm] = useState(null);
  const [pushEndDttm, setPushEndDttm] = useState(null);
  const [messageData, setMessageData] = useState(null);
  const [pushData, setPushData] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [height, setHeight] = useState(0);
  const getMessageHistoryLoading = useSelector(
    (state) => state.messageReducer.getMessageHistoryLoading
  );
  const getPushNotificationHistoryLoading = useSelector(
    (state) => state.messageReducer.getPushNotificationHistoryLoading
  );
  const messageList = useSelector((state) => state.messageReducer.messageList);
  const smsList = useSelector((state) => state.messageReducer.smsList);
  // const mmsList = useSelector((state) => state.messageReducer.mmsList);
  const lmsList = useSelector((state) => state.messageReducer.lmsList);
  const pushNotificationList = useSelector(
    (state) => state.messageReducer.pushNotificationList
  );
  const dispatch = useDispatch();
  const jgroupKey = useSelector(
    (state) => state.groupReducer.jgroup_key_inCookie
  );

  const onTabChange = (key) => {
    setTab(key);
  };

  const handleMenuItem = (item) => {
    setItem(item.key);
  };

  const onMessageKeywordChange = (e) => {
    setFilter(e.target.value);
  };

  const setForm = (started_dttm, ended_dttm, pushFilter, index) => {
    let form = new FormData();
    form.append("page_size", String(50));
    form.append("page_index", String(index));
    form.append("started_dttm", String(started_dttm));
    form.append("finished_dttm", String(ended_dttm));
    form.append("status", "all");
    // form.append("keyword", pushFilter ? String(pushFilter) : null);
    form.append("sort_properties", "created_dttm");
    form.append("sort_desc", "true");
    form.append("jgroup_key", `${jgroupKey}`);
    return form;
  };
  /** 실제 필터링이 구현된 함수
   * @param message 메시지
   * @param filter 입력된 키워드
   * @return message
   */
  const filterMessage = (message, filter) => {
    let addressee = message.PHONE || message.TR_PHONE || "";
    let msg = message.MSG || message.TR_MSG || "";
    if (
      addressee.trim().includes(filter.replaceAll("-", "")) ||
      msg.trim().includes(filter)
    ) {
      return message;
    } else {
      return null;
    }
  };

  /** 스크롤이 테이블의 끝에 닿으면 Index를 증가시키는 함수 */
  const handleIndex = () => {
    setIndex((index) => index + 1);
  };

  /** 필터링을 위해 사용되는 함수
   * @param list 불러온 메시지 내역
   */
  const handleMessageData = (list) => {
    if (filter) {
      setMessageData(
        list.filter((message) => {
          return filterMessage(message, filter);
        })
      );
    } else {
      setMessageData(list);
    }
  };

  /** 푸시 알림에서 날짜를 입력 후 확인 버튼을 누르면 호출되는 함수
   * @param value 폼에 입력한 값
   */
  const onFinish = (value) => {
    setScrollPosition(0);
    setIndex(0);
    switch (tab) {
      case "message":
        dispatch(
          getMessageHistoryAction({
            date: moment(value.message_month).format("YYYYMM"),
            jgroup_key: jgroupKey,
          })
        );
        break;
      case "push_notification":
        setPushStartDttm(value.push_start_date);
        setPushEndDttm(value.push_end_date);
        dispatch(
          getPushNotificationHistoryAction(
            setForm(value.push_start_date, value.push_end_date, pushFilter, 0)
          )
        );
        break;
    }
  };

  // const onPushNotificationKeywordChange = _.debounce((e) => {
  //   dispatch(
  //     getPushNotificationHistoryAction(
  //       setForm(pushStartDttm, pushEndDttm, e.target.value, 0)
  //     )
  //   );
  //   setIndex(0);
  // }, 800);

  /** 문자 탭에서 전체 상품, SMS, LMS를 선택 */
  useEffect(() => {
    if (tab === "message") {
      switch (item) {
        case "total":
          handleMessageData(messageList);
          break;
        case "sms":
          handleMessageData(smsList);
          break;
        // case "mms":
        //   handleMessageData(mmsList);
        //   break;
        case "lms":
          handleMessageData(lmsList);
          break;
      }
    } else if (tab === "push_notification") {
    }
  }, [tab, item, messageList, filter]);

  /** Page Index가 변경되면 그 다음 리스트를 가지고 옴 */
  useEffect(() => {
    if (index !== 0) {
      dispatch(
        getPushNotificationHistoryAction(
          setForm(pushStartDttm, pushEndDttm, pushFilter, index)
        )
      );
    }
  }, [index]);

  useEffect(() => {
    let tableHeight = document.getElementById("message_table").clientHeight;
    setHeight(tableHeight - 44);
    const updateHeight = () => {
      tableHeight = document.getElementById("message_table").clientHeight;
      setHeight(tableHeight - 44);
    };

    window.addEventListener("resize", updateHeight);
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const started_dttm = new Date(year, month, 1);
    const ended_dttm = new Date(year, month + 1, 0);
    setPushStartDttm(started_dttm);
    setPushEndDttm(ended_dttm);
    dispatch(
      getMessageHistoryAction({
        date: moment(date).format("YYYYMM"),
        jgroup_key: jgroupKey,
      })
    );
    dispatch(
      getPushNotificationHistoryAction(
        setForm(started_dttm, ended_dttm, pushFilter, 0)
      )
    );
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  useEffect(() => {
    /** 무한 스크롤의 경우 */
    if (pushData && index !== 0) {
      setPushData((pushData) => {
        return [...pushData, ...pushNotificationList.lamb_list];
      });
    } else {
      pushNotificationList ? setPushData(pushNotificationList.lamb_list) : null;
    }
  }, [pushNotificationList]);

  return (
    <Layout
      className="message-send"
      style={{ overflow: "auto", maxHeight: "calc(100% - 90px)" }}
    >
      <Header
        style={{
          display: "block",
          height: "auto",
          position: "relative",
        }}
      >
        <Tabs defaultActiveKey="message" onChange={onTabChange}>
          <TabPane tab="문자" key="message"></TabPane>
          <TabPane tab="운톡 푸쉬 알림" key="push_notification"></TabPane>
        </Tabs>
        <Spacer size={12} />
        <ConfigProvider locale={locale}>
          {tab === "message" ? (
            <DateContainer>
              <Form onFinish={onFinish} style={{ display: "flex" }}>
                <Form.Item name="message_month">
                  <DatePicker
                    placeholder=""
                    picker="month"
                    defaultValue={date}
                    locale={locale}
                    key="message_month"
                  />
                </Form.Item>

                <Spacer />
                <CustomButton
                  height="30px"
                  width="71px"
                  fontSize="14px"
                  text="확인"
                  borderRadius="5px"
                  htmlType="submit"
                />
              </Form>
            </DateContainer>
          ) : (
            <DateContainer>
              <Form onFinish={onFinish} style={{ display: "flex" }}>
                <Form.Item
                  name="push_start_date"
                  initialValue={moment(pushStartDttm)}
                >
                  <DatePicker
                    placeholder=""
                    defaultValue={pushStartDttm ? moment(pushStartDttm) : null}
                    locale={locale}
                    key="push_start_date"
                  />
                </Form.Item>
                <Spacer />
                <Form.Item
                  name="push_end_date"
                  initialValue={moment(pushEndDttm)}
                >
                  <DatePicker
                    placeholder=""
                    defaultValue={pushEndDttm ? moment(pushEndDttm) : null}
                    locale={locale}
                    key="push_end_date"
                  />
                </Form.Item>
                <Spacer />
                <CustomButton
                  height="30px"
                  width="71px"
                  fontSize="14px"
                  text="확인"
                  borderRadius="5px"
                  htmlType="submit"
                />
              </Form>
            </DateContainer>
          )}
        </ConfigProvider>
        {tab === "message" ? (
          <Caution>
            <Text size={12} color="#fff">
              • &nbsp; 문자 발송 시 통신사 사정에 의해 발송결과에 대한{" "}
              <Text size={12} style={{ fontWeight: 700 }}>
                응답 대기 시간
              </Text>
              이 소요될 수 있습니다.
            </Text>
            <br />
            <Text size={12} color="#fff">
              • &nbsp; 전송 결과 완료 후 메시지 전송 실패 건은 사용한
              충전금/포인트가 자동 환불 됩니다.{" "}
              <Text size={12} color="#EB5252" style={{ fontWeight: 700 }}>
                (약 2주 소요됩니다){" "}
              </Text>
            </Text>
          </Caution>
        ) : (
          <Caution>
            <Text size={12} color="#fff">
              • &nbsp; 운톡 어플이 설치된 경우 푸쉬 알림이 전송됩니다.
            </Text>
            <br />
            <Text size={12} color="#fff">
              • &nbsp;{" "}
              <Text size={12} color="#3B99F5">
                운톡 푸시알림은 비용이 발생하지 않습니다.
              </Text>
            </Text>
          </Caution>
        )}

        <Spacer size={14} />
        <div style={{ display: "flex" }}>
          {tab === "message" ? (
            <React.Fragment>
              <Dropdown
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                popupStyle={{ zIndex: 10500 }}
                trigger={"click"}
                overlay={<CustomMenu handleChange={handleMenuItem} />}
                // position="relative"
                overlayStyle={{ position: "absolute" }}
              >
                <div
                  style={{
                    background: "#4a4a4a",
                    padding: "12px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "141px",
                    justifyContent: "space-between",

                    height: "32px",
                    borderRadius: "3px",
                  }}
                >
                  <Text color="#fff" size={12}>
                    {item === "total"
                      ? "전체 메세지 타입"
                      : item === "sms"
                      ? "SMS"
                      : item === "lms"
                      ? "LMS"
                      : // : item === "mms"
                        // ? "MMS"
                        null}
                  </Text>
                  <Icon
                    width="12px"
                    height="6px"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icon/select/toggle_down.png"
                    }
                  />
                </div>
              </Dropdown>
              <Spacer />
            </React.Fragment>
          ) : null}
          <MessageSearchBar
            tab={tab}
            onMessageKeywordChange={onMessageKeywordChange}
            // onPushNotificationKeywordChange={onPushNotificationKeywordChange}
            messageData={messageData}
            pushData={pushNotificationList}
          />
        </div>
      </Header>
      <Spacer size={4.5} />
      <Content id="message_table">
        {(getMessageHistoryLoading || getPushNotificationHistoryLoading) && (
          <CustomSpinner />
        )}
        {tab === "message" ? (
          <MessageTable
            tab={tab}
            type={item}
            tableData={messageData}
            height={height}
          />
        ) : (
          <MessageTable
            tab={tab}
            tableData={pushData}
            handleIndex={handleIndex}
            index={index}
            totalIndex={pushNotificationList.page_total_count - 1}
            scrollPosition={scrollPosition}
            setScrollPosition={setScrollPosition}
            height={height}
          />
        )}
      </Content>
    </Layout>
  );
};

const DateContainer = styled.div`
  display: flex;
  position: absolute;
  right: 0px;
  top: 10px;
`;

const Caution = styled.div`
  width: 100%;
  padding: 12px;
  background: #4a4a4a;
  border-radius: 5px;
`;

export default MessageHistory;
