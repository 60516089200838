/** 쿠폰 혜택  Radio 버튼 */
export const couponTypeRadio = [
  {
    label: "QR교환권",
    value: "QR_EXCHANGE",
    key: 0,
  },
  {
    label: "운톡 마켓 할인권",
    value: "MARKET_DISCOUNT",
    key: 1,
  },
  {
    value: "PRIVATE_MARKET_DISCOUNT",
    key: 2,
  },
];

export const benefitRadio = [
  {
    label: "할인율",
    value: "DISCOUNT_PER",
    placeholder: "할인율 (숫자)",
    symbol: "%",
    key: 0,
    inputWidth: "99px",
  },
  {
    label: "할인액",
    value: "DISCOUNT_PRICE",
    placeholder: "할인가",
    symbol: "원",
    key: 1,
    inputWidth: "99px",
  },
  {
    label: "상품 증정",
    value: "PRESENT",
    placeholder: "상품 및 수량 입력",
    symbol: "* 센터에서 쿠폰 확인 후 상품을 교환 지급합니다.",
    key: 2,
    inputWidth: "237px",
  },
];
/** 전체로 할지 선택으로 할지 설정하는 Radio 버튼 */
export const productRadio = [
  {
    label: "전체 상품",
    value: "MARKET_TICKET_UNLIMITED",
    key: 0,
  },
  {
    label: "선택 상품",
    value: "LIMITED",
    key: 1,
  },
];
/** 유효기간을 설정하는 Radio 버튼 */
export const expirationRadio = [
  {
    label: "발행일 기준",
    value: "DAYS",
    key: 0,
  },
  {
    label: "기간 설정",
    value: "SPECIFIC",
    key: 1,
  },
];
/** 쿠폰의 발급 수량을 설정하는 Radio 버튼 */
export const countRadio = [
  {
    label: "무제한",
    value: false,
    key: 0,
  },
  {
    label: "한정 수량",
    value: true,
    key: 1,
  },
];

// export const expirationCheckBox = [
//   { label: "알림 없음", value: "NONE", key: 0 },
//   { label: "만료 당일", value: "TODAY", key: 1 },
//   { label: "만료 1일전", value: "ONEDAY", key: 2 },
//   { label: "만료 3일전", value: "THREEDAYS", key: 3 },
//   { label: "만료 5일전", value: "FIVEDAYS", key: 4 },
//   { label: "만료 7일전", value: "SEVENDAYS", key: 5 },
// ];

// export const expirationNoticeSelect = [
//   { label: "문자", value: "message", key: 0 },
//   { label: "운톡 푸쉬 알림", value: "push", key: 1 },
//   { label: "스마트 전송", value: "smart", key: 2 },
// ];
