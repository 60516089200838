import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Api } from "./Api";

/**쿠폰 가져오는 API*/
export const getCoupon = async (data) => {
  try {
    const response = await axios.get(
      `${Api.ServerJSON.getInstance().BROJ_SERVER}api/coupon/jgroup/${
        data.jgroup_key
      }`
    );
    return response.data.result;
  } catch (e) {
    console.log(e);
  }
};

/**쿠폰 생성하는 API*/
export const createCoupon = async (data) => {
  try {
    const response = await axios.post(
      `${Api.ServerJSON.getInstance().BROJ_SERVER}api/coupon/jgroup/${
        data.jgroup_key
      }`,
      data.couponData
    );
    return response.data.result;
  } catch (e) {
    console.log(e);
  }
};

/**쿠폰 수정하는 API*/
export const modifyCoupon = async (data) => {
  try {
    const response = await axios.patch(
      `${Api.ServerJSON.getInstance().BROJ_SERVER}api/coupon/jgroup/${
        data[2]
      }/couponparent/${data[1]}`,
      data[0]
    );
    return response.data.result;
  } catch (e) {
    console.log(e);
  }
};

/** 쿠폰 삭제하는 API
 * @param data 선택된 쿠폰 데이터들
 */
export const deleteCoupon = async (data) => {
  try {
    let keys = data.selectedRows.map((item) => item.jgcp_key);
    const response = await axios.delete(
      `${Api.ServerJSON.getInstance().BROJ_SERVER}api/coupon/jgroup/${
        data.jgroup_key
      }/couponparents/${keys.join(",")}`
    );
    return response.data.result;
  } catch (e) {
    console.log(e);
  }
};

/** 회원의 쿠폰 목록 가져오는 API*/
export const getMemberCoupon = async (data) => {
  const response = await axios.get(
    `${
      Api.ServerJSON.getInstance().BROJ_SERVER
    }api/coupon/jgroup/98901/member/98902`
  );
  return response.data.result;
};

/**발행된 쿠폰들을 회수하는 API*/
export const retrieveIssuedCoupon = async (data) => {
  try {
    const response = await axios.delete(
      `${Api.ServerJSON.getInstance().BROJ_SERVER}api/coupon/jgroup/${
        data.data.jgroup_key
      }/coupons/${data.keys}`
    );
    return response.data.result;
  } catch (e) {
    console.log(e);
  }
};

/**발행된 쿠폰들을 가져오는 API */
export const getIssuedCoupon = async (data) => {
  try {
    const response = await axios.get(
      `${Api.ServerJSON.getInstance().BROJ_SERVER}api/coupon/jgroup/${
        data.jgroup_key
      }/couponparent/${data.jgcp_key}/issued-coupon`
    );
    return response.data.result;
  } catch (e) {
    console.log(e);
  }
};

/**
 * 마켓의 상품을 가져오는 API
 * @param data 마켓의 상품키
 */
export const getMarketProduct = async (data) => {
  try {
    const response = await axios.get(
      `${
        Api.ServerJSON.getInstance().BROJ_MARKET_SERVER
      }product/ticket-goods/jgroup/${data.jgroup_key}`
    );
    return response.data.result;
  } catch (e) {
    console.log(e);
  }
};

/**
 * CRM의 상품을 가져오는 API
 * @param data 상품키
 * @deprecated 고도화때 쓸거고, 내용 바꿔야해요
 */
export const getCrmProduct = async (data) => {
  try {
    const response = await axios.get(
      `https://server.broj.shop/BroJServer/jgroup/api/jgproducts?jgroup_key=31105&jgp_type=ticket&jbrand_key=4396`
    );
    return response.data.jgproducts;
  } catch (e) {
    console.log(e);
  }
};

export const getGoodsByKeys = async (data) => {
  try {
    const response = await axios.get(
      `${Api.ServerJSON.getInstance().BROJ_MARKET_SERVER}product/goodslist`,
      {
        params: { goods_keys: data },
      }
    );
    return response.data.result;
  } catch (e) {
    console.log(e);
  }
};

export const getPrivateMarket = async (groupKey) => {
  const { data } = await axios.get(
    `${
      Api.ServerJSON.getInstance().BROJ_MARKET_SERVER
    }v1/admin/private-markets/${groupKey}`
  );
  return data?.result;
};

/**
 * parivate 마켓의 상품을 가져오는 API
 * @param groupKey 그룹키
 * @param productTypes 상품타입
 */
export const getPrivateMarketProducts = async ({ groupKey, productTypes }) => {
  try {
    const response = await axios.get(
      `${
        Api.ServerJSON.getInstance().BROJ_SERVER
      }v1/admin/groups/${groupKey}/products`,
      {
        params: { filter_product_types: productTypes },
      }
    );
    return response.data.result;
  } catch (e) {
    console.log(e);
  }
};

/**
 * jgroup의 해당하는 모든 굿즈들 가져오는 API
 * @param groupKey 그룹키
 */
export const getGoodsByGroup = async ({groupKey}) => {
  try {
    const response = await axios.get(
      `${
        Api.ServerJSON.getInstance().BROJ_MARKET_SERVER
      }product/all-ticket-goods/jgroup/${groupKey}`,
    );
    return response.data.result;
  } catch (e) {
    console.log(e);
  }
};
