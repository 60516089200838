import {
    Form,
    Radio,
    DatePicker,
    Switch,
    Divider,
    Popover,
    Layout,
    Tabs,
    ConfigProvider,
    Upload,
    Button,
    Input,
    message,
    Menu,
    Dropdown,
} from 'antd';
import { Content, Footer, Header } from 'antd/lib/layout/layout';
import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import {
    ModalScreen,
    Spacer,
    Text,
    PositionController,
    Title,
    ActionButtonGroup,
    CloseButton,
    Icon,
} from '../styles/commonStyle';
import { commonTheme } from '../styles/theme';
import '../styles/CreateAndModifyCouponModal.css';
import SelectProductMenu from './SelectProductMenu';
import SelectPrivateMarketProductView from './SelectPrivateMarketProductView';
import { createCouponAction, modifyCouponAction } from '../reducers/coupon';
import { useDispatch, useSelector } from 'react-redux';
import {
    benefitRadio,
    productRadio,
    expirationRadio,
    countRadio,
    couponTypeRadio,
} from './ModalDataEntry';
import moment from 'moment';
import CouponPreviewModal from './CouponPreviewModal';
import NoticeModal from './NoticeModal';
import { CustomButton } from './CustomButton';
import CustomInput from './CustomInput';
import {
    validateCount,
    validateDateRange,
    validatePeriod,
    validatePresent,
    validatePrice,
    validateRate,
} from './Validator';
const { TabPane } = Tabs;

import locale from 'antd/lib/locale/ko_KR';
import 'moment/locale/ko';
import CustomSpinner from './CustomSpinner';
import ImageBox from './ImageBox';
import { getMessagePresignedUrl } from '../api/s3';
import shortid from 'shortid';
import { getPrivateMarket } from '../api/couponAPI';

moment.locale('ko');

const { RangePicker } = DatePicker;

// 브랜드 상품 타입
const items = [
    // {
    //     label: `입장권`,
    //     key: 'TICKET_MEMBER',
    //     value: 'TICKET_MEMBER',
    // },
    {
        label: `수강권`,
        key: 'TICKET_LESSON',
        value: 'TICKET_LESSON',
    },
    // {
    //   label: `락커`,
    //   key: "BRAND_LOCKER_MEMBER",
    //   value: "BRAND_LOCKER_MEMBER",
    // },
    // {
    //   label: `운동복`,
    //   key: "BRAND_SPORTS_MEMBER",
    //   value: "BRAND_SPORTS_MEMBER",
    // },
    // {
    //   label: `일반`,
    //   key: "BRAND_NORMAL_MEMBER",
    //   value: "BRAND_NORMAL_MEMBER",
    // },
];

// 프라이빗 마켓 상품 타입
const privateMarketProductTypes = [
    {
        label: `입장권`,
        key: 'LESSON_ENTRY',
        value: 'ENTRY',
    },
    {
        label: `예약권`,
        key: 'LESSON_CLASS',
        value: 'CLASS',
    },
    {
        label: `시설권`,
        key: 'LESSON_FACILITY',
        value: 'FACILITY',
    },
    {
        label: `락커`,
        key: 'LOCKER',
        value: 'LOCKER',
    },
    {
        label: `운동복`,
        key: 'SPORTS',
        value: 'SPORTS',
    },
];

let coupon = {
    jgcp_name: null, // string - 쿠폰명
    jgcp_reason: '', // string - 쿠폰 상세 설명
    gift_type: 'DISCOUNT_PER', // string - 할인율, 할인가, 증정 상품 입력
    gift_value: null, // number - 할인율, 할인가일때만! 증정 상품인 경우 null
    present_name: null, // 증정 상품의 이름
    available_type: 'MARKET_TICKET_UNLIMITED', // string - 전체 상품 혹은 선택 상품
    available_products: [], // array - 쿠폰 적용 가능한 상품
    available_crm_product_keys: [], // array - 쿠폰 적용 가능한 프라이빗 마켓 상품
    period_type: 'DAYS', // string - 쿠폰 유효기간을 발행 후 몇일 이내, 또는 특정기간으로 설정 시
    jgcp_period_start: null, // number - 쿠폰 유효기간 시작일
    jgcp_period_end: null, // number - 쿠폰 유효기간 만료일
    jgcp_preiod_day: null, // number - 쿠폰 유효기간 길이, period type이 DAYS 일때 << (!오타 있음!)
    thumbnail: null,
    jgcp_limited: false, // boolean - 수량 없음 : false, 수량 있음 : true
    jgcp_limit_count: null, // number - 한정 수량이 있는 경우! 없으면 null
    expired_alarm: null, // boolean - 알림 없음 : false, 그 외 : true
    expired_alarm_day: null, // string - 없음 ~ 당일 7일전
    active: true, // boolean - 쿠폰 사용 여부

    is_share_coupon_url: null, // 공유할 쿠폰 URL(GLOBAL) 생성 여부
    web_contents_img_paths: [], // 웹 HTML에 띄울 이미지 리스트
    web_expired_dttm: null, // 공유된 쿠폰 URL 만료 시간

    thumbnail: null, // 썸네일 (!추후 고도화에서 적용!)
};

const dayList = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
];
const hourList = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23,
];
const minuteList = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 59];
const env = process.env.REACT_APP_ENVIRONMENT;
const config = require('../config/config.json')[env];

/** 쿠폰 생성 및 수정 모달창입니다
 * @param isModify 쿠폰 수정하기 버튼을 눌러서 접근함을 나타내는 변수
 * @param isCheck 쿠폰 확인하기 버튼을 눌러서 접근함을 나타내는 변수
 * @param handleChange 모달창을 닫는 함수
 * @param record 특정 행의 쿠폰 데이터를 나타내는 변수
 */
const CreateAndModifyCouponModal = ({
    isCheck,
    isModify,
    handleChange,
    record,
}) => {
    const couponList = useSelector((state) => state.couponReducer.couponList);
    const modifyCouponDone = useSelector(
        (state) => state.couponReducer.modifyCouponDone,
    );
    const createCouponDone = useSelector(
        (state) => state.couponReducer.createCouponDone,
    );
    const createCouponLoading = useSelector(
        (state) => state.couponReducer.createCouponLoading,
    );
    const modifyCouponLoading = useSelector(
        (state) => state.couponReducer.modifyCouponLoading,
    );
    const privateMarketProducts = useSelector(
        (state) => state.couponReducer.privateMarketProducts
      );
    const allGoods = useSelector((state) => state.couponReducer.goodsByGroup);
    const [selectedBenefitType, setSelectedBenefitType] = useState(() => {
        if ((isModify || isCheck) && record.available_type !== 'NONE') {
            return benefitRadio.find(
                (benefit) => benefit.value === record.gift_type,
            );
        }
        return benefitRadio[0];
    });
    const [selectedCouponType, setSelectedCouponType] = useState(() => {
        if ((isModify || isCheck) && record.gift_type === 'PRESENT') {
            return couponTypeRadio[0];
        } else if ((isModify || isCheck) && record.gift_type !== 'PRESENT') {
            // 프라이빗 마켓, 브랜드 마켓 분기 로직
            if (
                record.available_type === 'PRIVATE_MARKET_TICKET_UNLIMITED' ||
                record.available_type === 'PRIVATE_MARKET_TICKET_LIMITED'
            ) {
                return couponTypeRadio[2];
            }
            return couponTypeRadio[1];
        }
        return couponTypeRadio[0];
    });
    const [selectedProduct, setSelectedProduct] = useState(() => {
        if ((isModify || isCheck) && record.available_type === 'NONE') {
            return productRadio[0];
        } else if ((isModify || isCheck) && record.available_type !== 'NONE') {
            if (
                record.available_type === 'PRIVATE_MARKET_TICKET_UNLIMITED'
            ) {
                return productRadio[0];
            }
            if (
                record.available_type === 'PRIVATE_MARKET_TICKET_LIMITED'
            ) {
                return productRadio[1];
            }
            return productRadio.find(
                (product) => product.value === record.available_type,
            );
        }
        return productRadio[0];
    });
    const [selectedAvailableType, setSelectedAvailableType] = useState(() => {
        if ((isModify || isCheck) && record.available_type !== 'NONE') {
            return productRadio.find(
                (product) => product.value === record.available_type,
            );
        }
        return productRadio[0];
    });
    const [selectedMarketProduct, setSelectedMarketProduct] = useState(() => {
        if (isModify || isCheck) {
            if (
                record.available_type === productRadio[0].value ||
                record.available_type === 'MARKET_TICKET_UNLIMITED'
            ) {
                return allGoods.filter((product) =>
                    product.market_type === 'BRAND_MARKET' &&
                    (product.product_kind === 'BRAND_TICKET_LESSON' || 
                    product.product_kind === 'CRM_TICKET_LESSON'))
            }
            if (
                record.available_type === 'PRIVATE_MARKET_TICKET_UNLIMITED' 
            ) {
                return privateMarketProducts?.filter(
                    (product) => product?.market_options !== null
                );
            }
            if (record.available_type === 'PRIVATE_MARKET_TICKET_LIMITED') {
                return record.available_crm_product_keys.map((key) => 
                    privateMarketProducts.find(
                        (product) => product.key === key
                    )).filter(product => product !== undefined);
            }

            let index = 0;
            if (record.available_products) {                
                return record.available_products.map((key) => 
                    allGoods.find((product) => 
                        key === product.goods_key && 
                        product.market_type === 'BRAND_MARKET' && 
                        (product.product_kind === 'BRAND_TICKET_LESSON' || 
                         product.product_kind === 'CRM_TICKET_LESSON')
                    ))
                .filter(item => item !== undefined);
            }

            return [];
        }
        return [];
    });
    const [selectedCount, setSelectedCount] = useState(() => {
        if (isModify || isCheck) {
            return countRadio.find(
                (count) => count.value === record.jgcp_limited,
            );
        }
        return countRadio[0];
    });
    const [selectedExpiration, setSelectedExpiration] = useState(() => {
        if (isModify || isCheck) {
            return expirationRadio.find(
                (expiration) => expiration.value === record.period_type,
            );
        }
        return expirationRadio[0];
    });
    const [checkedSelectProduct, setCheckedSelectProduct] = useState(false);
    const [pbClicked, setPbClicked] = useState(false);

    const [modifyCompleteModal, setModifyCompleteModal] = useState(false);
    const [createCompleteModal, setCreateCompleteModal] = useState(false);

    const [finished, setFinished] = useState(false);
    const [previewName, setPreviewName] = useState(null);
    const [previewGiftType, setPreviewGiftType] = useState(null);
    const [previewGift, setpreviewGift] = useState(null);
    const [previewPeriodType, setPreviewPeriodType] = useState(null);
    const [previewPreiodDay, setPreviewPreiodDay] = useState(null);
    const [previewPeriod, setPreviewPeriod] = useState(null);
    const [previewPeriodStart, setPreviewPeriodStart] = useState(null);
    const [previewPeriodEnd, setPreviewPeriodEnd] = useState(null);
    const [selectPlatformTab, setSelectPlatformTab] = useState('market');
    const [isUse, setIsUse] = useState(() => {
        if (isModify && record) {
            if (record.active) {
                if (record.period_type === 'SPECIFIC') {
                    const now = new Date();
                    // 시간을 0시 0분 0초로 설정합니다.
                    const jgcpPeriodEndDate = new Date(record.jgcp_period_end);
                    jgcpPeriodEndDate.setHours(23, 59, 59, 59);
                    if (jgcpPeriodEndDate < now) {
                        return false;
                    }
                } else {
                    return true;
                }
            } else {
                return false;
            }
        }
        return true;
    });

    const [disabled, setDisabled] = useState(() => {
        if (isModify && record) {
            if (record.active) {
                if (record.period_type === 'SPECIFIC') {
                    const now = new Date();
                    // 시간을 0시 0분 0초로 설정합니다.
                    const jgcpPeriodEndDate = new Date(record.jgcp_period_end);
                    jgcpPeriodEndDate.setHours(23, 59, 59, 59);
                    if (jgcpPeriodEndDate < now) {
                        return true;
                    }
                }
            }
        }
        return false;
    });

    const [selectMarketProductTab, setSelectMarketProductTab] =
        useState(() => {
            if (isModify && record) {
                if (record.available_type === 'PRIVATE_MARKET_TICKET_UNLIMITED' || record.available_type === 'PRIVATE_MARKET_TICKET_LIMITED') {
                    return 'LESSON_ENTRY';
                }
            }
            return 'TICKET_LESSON';
        });
    const formRef = useRef();
    const [cancleModifyModal, setCancleModifyModal] = useState(false);
    const [preiodDay, setPreiodDay] = useState(() => {
        if (isModify || isCheck) {
            return record.jgcp_preiod_day;
        }
        return null;
    });
    const [periodStart, setPeriodStart] = useState(() => {
        if ((isModify || isCheck) && record.jgcp_period_start) {
            return moment(record.jgcp_period_start);
        }
        return moment(new Date());
    });
    const [periodEnd, setPeriodEnd] = useState(() => {
        if ((isModify || isCheck) && record.jgcp_period_end) {
            return moment(record.jgcp_period_end);
        }
    });
    const [period, setPeriod] = useState(() => {
        let date = [];
        if ((isModify || isCheck) && record.jgcp_period_start) {
            date.push(moment(record.jgcp_period_start));
        } else {
            date.push(moment(new Date()));
        }

        if ((isModify || isCheck) && record.jgcp_period_end) {
            date.push(moment(record.jgcp_period_end));
        }
        return date;
    });

    /**쿠폰 URL 생성 관련 state */
    // console.log("record", record);
    const [createUrl, setCreateUrl] = useState(() => {
        if (isModify && record) {
            if (record.is_share_coupon_url) {
                return record.is_share_coupon_url;
            }
        } else {
            return false;
        }
    });
    const [imageList, setImageList] = useState([]);
    const [imageUrlList, setImageUrlList] = useState([]);
    const [existImageUrlList, setExistImageUrlList] = useState([]);
    const [isEventEndTime, setIsEventEndTime] = useState(() => {
        if (isModify && record) {
            if (record.web_expired_dttm) {
                return true;
            }
        } else {
            return false;
        }
    });
    const [eventEndTimeData, setEventEndTimeData] = useState(() => {
        if (isModify && record) {
            if (record.web_expired_dttm) {
                const expiredDate = moment(record.web_expired_dttm);
                const updatedDate = moment(record.jgcp_updated_dttm);
                // const updatedDate = new Date(record.jgcp_updated_dttm);
                const duration = moment.duration(expiredDate.diff(updatedDate));
                // const endDate = expiredDate - updatedDate;
                const day = duration.days();
                const hour = duration.hours();
                let minute = duration.minutes();
                if (minute !== 59) {
                    minute = minute + 1;
                }

                return {
                    day: day,
                    hour: hour,
                    minute: minute,
                };
            } else {
                return {
                    day: 0,
                    hour: 0,
                    minute: 0,
                };
            }
        } else {
            return {
                day: 0,
                hour: 0,
                minute: 0,
            };
        }
    });
    const [privateMarket, setPrivateMarket] = useState(null);
    const [isProductAddBlocked, setIsProductAddBlocked] = useState(false);

    // const [isPressCreateAndmodifyBtn, setIsPressCreateAndmodifyBtn] =
    //   useState(false);

    const imageInputRef = useRef();

    const MenuItem = Menu.Item;

    const menuStyle = {
        backgroundColor: '#4a4a4a',
    };

    const menuItemStyle = {
        background: 'none',
        color: '#ffffff',
    };

    const days = (
        <Menu
            style={menuStyle}
            onClick={(e) => {
                const params = {
                    value: 'day',
                };
                onEventEndTimeChange(e, params);
            }}
        >
            {dayList.map((day) => {
                return (
                    <MenuItem key={day} style={menuItemStyle}>
                        {day}
                    </MenuItem>
                );
            })}
        </Menu>
    );

    const hours = (
        <Menu
            style={menuStyle}
            onClick={(e) => {
                const params = {
                    value: 'hour',
                };
                onEventEndTimeChange(e, params);
            }}
        >
            {hourList.map((hour) => {
                return (
                    <MenuItem key={hour} style={menuItemStyle}>
                        {hour}
                    </MenuItem>
                );
            })}
        </Menu>
    );

    const minutes = (
        <Menu
            style={menuStyle}
            onClick={(e) => {
                const params = {
                    value: 'minute',
                };
                onEventEndTimeChange(e, params);
            }}
        >
            {minuteList.map((minute) => {
                return (
                    <MenuItem key={minute} style={menuItemStyle}>
                        {minute}
                    </MenuItem>
                );
            })}
        </Menu>
    );
    const jgroupKey = useSelector(
        (state) => state.groupReducer.jgroup_key_inCookie,
    );

    //   const [selectedNoticeMethod, setSelectedNoticeMethod] = useState(
    //   expirationNoticeSelect[0]
    // );
    // const [notNotice, setNotNotice] = useState(false);

    const dispatch = useDispatch();
    const onChangeName = (e) => {
        setPreviewName(e.target.value);
    };

    const onChangeGiftType = (e) => {
        setPreviewGiftType(e.target.value);
    };

    const onChangeGift = (e) => {
        setpreviewGift(e.target.value);
    };

    const onChangeUse = (e) => {};

    const setPreviewData = () => {
        setPreviewName(record.jgcp_name);
        setPreviewGiftType(record.gift_type);
        record.present_name === null && setpreviewGift(record.gift_value);
        setPreviewPeriodType(record.period_type);
        setPreviewPreiodDay(record.jgcp_preiod_day);
        setPreviewPeriod(period);
    };

    useEffect(() => {
        if (isModify || isCheck) {
            setPreviewData();
        } else {
            setPreviewGiftType(benefitRadio[0].value);
            setPreviewPeriodType(expirationRadio[0].value);
        }
    }, []);

    const onChangePreiodDay = (e) => {
        setPreviewPreiodDay(e.target.value);
    };

    const onChangePeriodStart = (date) => {
        setPeriodStart(date);
        setPreviewPeriodStart(date);
    };

    const onChangePeriodEnd = (date) => {
        setPeriodEnd(date);
        setPreviewPeriodEnd(date);
    };

    const onChangePeriodType = (e) => {
        setPreviewPeriodType(e.target.value);
    };

    const handleQrExchangeClick = () => {
        setSelectedCouponType(couponTypeRadio[0]);
    };
    const handleMarketDiscountClick = () => {
        setSelectMarketProductTab('TICKET_LESSON');
        setSelectedCouponType(couponTypeRadio[1]);
    };
    const handlePrivateMarketDiscountClick = () => {
        setSelectMarketProductTab('LESSON_ENTRY');
        setSelectedCouponType(couponTypeRadio[2]);
    };

    const onChangeCouponType = (e) => {
        
        switch (e.target.value) {
            case 'MARKET_DISCOUNT':
            case 'PRIVATE_MARKET_DISCOUNT':
                // setSelectedProduct(productRadio[0])
                setPreviewGiftType(selectedBenefitType.value);
                setSelectedMarketProduct([]);
                break;
            case 'QR_EXCHANGE':
                setPreviewGiftType(benefitRadio[2].value);
                break;
        }
    };

    /**할인율 */
    const handleBenefitRateClick = () => {
        setSelectedBenefitType(benefitRadio[0]);
    };
    /**할인액 */
    const handleBenefitAmountClick = () => {
        setSelectedBenefitType(benefitRadio[1]);
    };
    /** 상품 증정*/
    const handleBenefitGiftClick = () => {
        setSelectedBenefitType(benefitRadio[2]);
    };
    /**발행일 기준*/
    const handleIssueDateClick = () => {
        setSelectedExpiration(expirationRadio[0]);
    };
    /**기간 설정*/
    const handleDurationClick = () => {
        setSelectedExpiration(expirationRadio[1]);
    };
    /**전체 상품*/
    const handleTotalProductClick = () => {
        setSelectedProduct(productRadio[0]);
    };
    /**선택 상품*/
    const handleSelectProductClick = () => {
        setSelectedProduct(productRadio[1]);
    };

    /**무제한*/
    const handleCountInfiniteClick = () => {
        setSelectedCount(countRadio[0]);
    };

    /**한정 수량*/
    const handleCountLimitClick = () => {
        setSelectedCount(countRadio[1]);
    };

    /**선택 상품 Select 버튼을 클릭하면 선택 모달창이 나오게 하는 함수 */
    const handleCheckedSelectProduct = () => {
        setCheckedSelectProduct(!checkedSelectProduct);
    };

    const handleSelectPlatformTab = (value) => {
        setSelectedMarketProduct([]);
        setSelectMarketProductTab('TICKET_LESSON');
        setSelectPlatformTab(value);
    };

    const handleSelectedMarketProduct = (selectedMarketProduct) => {
        // setIsPressCreateAndmodifyBtn(false);
        setSelectedMarketProduct(selectedMarketProduct);
    };

    const handleSelectMarketProductTab = (marketProductTab) => {
        setSelectMarketProductTab(marketProductTab);
    };

    const handleModal = () => {
        if (isModify) {
            handleCancleModal();
        } else {
            handleChange();
        }
    };

    const handleCancleModal = () => {
        setCancleModifyModal((cancleModifyModal) => !cancleModifyModal);
    };

    const handlePre = () => {
        setPbClicked((pbClicked) => !pbClicked);
    };

    const onFileChange = (e) => {
        if (imageList.length + e.target.files.length > 10) {
            message.warning({
                content: '이미지 등록은 최대 10장 입니다.',
                style: {
                    marginTop: '90vh',
                },
                duration: 1,
            });
            return;
        } else {
            let urlList = [];
            const newFileList = Array.from(e.target.files);
            newFileList.forEach((file) => {
                const currentImageUrl = URL.createObjectURL(file);
                urlList.push(currentImageUrl);
            });

            setImageUrlList((prev) => [...prev, ...urlList]);
            setImageList((prev) => [...prev, ...newFileList]);
        }
    };

    const deleteImage = (idx) => {
        const newImageList = [...imageList];
        const newImageUrlList = [...imageUrlList];
        newImageList.splice(idx, 1);
        newImageUrlList.splice(idx, 1);
        setImageList(newImageList);
        setImageUrlList(newImageUrlList);
    };

    const deleteExistImage = (idx) => {
        const newImageList = [...imageList];
        const newImageUrlList = [...existImageUrlList];
        newImageList.splice(idx, 1);
        newImageUrlList.splice(idx, 1);
        setImageList(newImageList);
        setExistImageUrlList(newImageUrlList);
    };

    const onEventEndTimeChange = (e, params) => {
        let newObj = { ...eventEndTimeData };
        if (params.value === 'day') {
            newObj.day = Number(e.key);
        }
        if (params.value === 'hour') {
            newObj.hour = Number(e.key);
        }
        if (params.value === 'minute') {
            newObj.minute = Number(e.key);
        }
        setEventEndTimeData(newObj);
    };

    const fileSettng = async (imageName, selectedFile) => {
        //url 생성
        const uploadUrl = `open-coupon/${imageName}`;

        // presigned url 생성
        const messagePresignedURL = await getMessagePresignedUrl(uploadUrl);

        // const fileObj = await fetch(selectedFile);
        // const imageBlob = await fileObj.blob();

        const putImageReult = await fetch(messagePresignedURL, {
            method: 'PUT',
            body: selectedFile,
            headers: {
                'Content-Type': 'image/jpeg',
            },
        });
        return putImageReult;
    };

    /**생성하기 또는 수정하기 버튼을 클릭 했을 때, Form의 onFinish 속성에 넣을 함수
     * @param values Form에서 사용자가 선택한 값들
     */
    const onFinish = async (values) => {
        // if (
        //   !isEventEndTime &&
        //   eventEndTimeData.day === 0 &&
        //   eventEndTimeData.hour === 0
        // ) {
        //   message.warning({
        //     content: "이벤트 마감시간을 입력해 주세요.",
        //     style: {
        //       marginTop: "90vh",
        //     },
        //     duration: 1,
        //   });
        //   return;
        // }
        if (values.coupon_type === 'PRIVATE_MARKET_DISCOUNT') {
            Object.assign(values, {
                available_crm_product_keys: selectedMarketProduct.map(
                    (product) => product.key,
                ),
            });
            values.available_products = [];
        } 
        if (values.coupon_type === 'MARKET_DISCOUNT') {
            Object.assign(values, {
                available_products: selectedMarketProduct.map(
                    (product) => product.goods_key,
            ),
            });
            values.available_crm_product_keys = [];
        }
        if (values.coupon_type === 'QR_EXCHANGE') {
            values.available_type = 'NONE';
            values.gift_type = 'PRESENT';
            values.gift_value = null;
            values.available_products = [];
            values.available_crm_product_keys = [];
            Object.assign(values, {
                present_name: values.jgcp_name,
            });
        }
        if (
            values.gift_type === 'DISCOUNT_PER' ||
            values.gift_type === 'DISCOUNT_PRICE'
        ) {
            values.present_name = null;
            values.gift_value = parseInt(values.gift_value);
            // 프라이빗 마켓 쿠폰일 경우 전체상품, 선택상품 타입 분기
            if (values.coupon_type === 'PRIVATE_MARKET_DISCOUNT') {
                if (values.available_type === 'MARKET_TICKET_UNLIMITED') {
                    values.available_type = 'PRIVATE_MARKET_TICKET_UNLIMITED';
                } else if (values.available_type === 'LIMITED') {
                    values.available_type = 'PRIVATE_MARKET_TICKET_LIMITED';
                }
            }
        }

        if (values.period_type === 'DAYS') {
            values.jgcp_period_start = null;
            values.jgcp_period_end = null;
        } else {
            values.jgcp_preiod_day = null;
            Object.assign(values, {
                jgcp_period_start: values.jgcp_specific_period[0],
                jgcp_period_end: values.jgcp_specific_period[1],
            });
        }
        delete values.jgcp_specific_period;
        delete values.coupon_type;

        if (values.jgcp_limited === 'false') {
            values.jgcp_limit_count = null;
        }
        //   const updatedDate = new Date();
        //   let futureDate = new Date(updatedDate.getTime());

        //   futureDate.setDate(futureDate.getDate() + eventEndTimeData.day);
        //   futureDate.setHours(futureDate.getHours() + eventEndTimeData.hour);
        //   futureDate.setMinutes(
        //     futureDate.getMinutes() + eventEndTimeData.minute
        //   );
        //   timestamp = new Date(futureDate).toUTCString();
        // }
        let timestamp;
        if (isEventEndTime) {
            if (!isModify) {
                const now = new Date(); // 현재 일시
                let futureDate = new Date(now.getTime()); // 현재 일시를 기준으로 복사

                futureDate.setDate(futureDate.getDate() + eventEndTimeData.day);
                futureDate.setHours(
                    futureDate.getHours() + eventEndTimeData.hour,
                );
                futureDate.setMinutes(
                    futureDate.getMinutes() + eventEndTimeData.minute,
                );
                timestamp = new Date(futureDate).toUTCString();
            } else if (isModify) {
                const updatedDate = new Date();
                let _futureDate = new Date(updatedDate.getTime());
                _futureDate.setDate(
                    _futureDate.getDate() + eventEndTimeData.day,
                );
                _futureDate.setHours(
                    _futureDate.getHours() + eventEndTimeData.hour,
                );
                _futureDate.setMinutes(
                    _futureDate.getMinutes() + eventEndTimeData.minute,
                );
                timestamp = new Date(_futureDate).toUTCString();
            }
        } else {
            timestamp = null;
        }
        values['web_expired_dttm'] = timestamp;

        let imageNameList = [];
        if (imageList && imageList.length > 0) {
            imageList.forEach((image) => {
                const uuid = `${shortid.generate()}.png`;
                imageNameList.push(uuid);
            });

            imageList.forEach(async (image, index) => {
                const uuid = imageNameList[index];
                await fileSettng(uuid, image);
            });
        }
        values['web_contents_img_paths'] = [
            ...existImageUrlList,
            ...imageNameList,
        ];

        coupon = Object.assign({}, coupon, values); // 덮어 쓰기
        if (isModify) {
            dispatch(modifyCouponAction([coupon, record.jgcp_key, jgroupKey]));
        } else {
            // console.log("!!!!!!!!!,", values);
            dispatch(
                createCouponAction({
                    couponData: coupon,
                    jgroup_key: jgroupKey,
                }),
            );
        }
        setFinished(true);
    };

    const onFinishFailed = (errorInfo) => {
        // console.log("Validation failed:", errorInfo);
    };

    // const initialValues = {
    //   jgcp_name: (isModify || isCheck) && record ? record.jgcp_name : null,
    //   jgcp_reason: (isModify || isCheck) && record ? record.jgcp_reason : null,
    //   active: (isModify || isCheck) && record ? record.active : true,
    //   gift_type:
    //     (isModify || isCheck) && record
    //       ? record.gift_type
    //       : benefitRadio[0].value,
    //   gift_value:
    //     (isModify || isCheck) && record
    //       ? record.gift_type === "PRESENT"
    //         ? record.present_name
    //         : record.gift_value
    //       : null,
    //   available_type:
    //     (isModify || isCheck) && record
    //       ? record.available_type
    //       : productRadio[0].value,
    //   period_type:
    //     (isModify || isCheck) && record
    //       ? record.period_type
    //       : expirationRadio[0].value,
    //   jgcp_preiod_day: (isModify || isCheck) && record ? preiodDay : null,
    //   jgcp_period_start: (isModify || isCheck) && record ? periodStart : null,
    //   jgcp_period_end: (isModify || isCheck) && record ? periodEnd : null,
    //   jgcp_limited:
    //     record && record.jgcp_limited ? countRadio[1].value : countRadio[0].value,
    //   jgcp_limit_count:
    //     record && record.jgcp_limit_count ? record.jgcp_limit_count : null,
    // };

    useEffect(() => {
        if (createCouponDone && finished) {
            setCreateCompleteModal(true);
        } else if (modifyCouponDone && finished) {
            setModifyCompleteModal(true);
        }
    }, [modifyCouponDone, createCouponDone]);

    useEffect(() => {
        if (isModify && record.period_type === 'SPECIFIC') {
            const nowDate = new Date();
            const selectedStartDate = new Date(period[0]);
            const selectedEndDate = new Date(period[1]);
            selectedEndDate.setHours(23, 59, 59, 59);
            if (selectedEndDate > nowDate) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        } else {
            return;
        }
    }, [period]);

    useEffect(() => {
        async function _setIsEndTime() {
            await setIsEventEndTime(false);
        }
        async function resetCouponUrlData() {
            await _setIsEndTime();
            // setImageList([]);
            // setImageUrlList([]);
            // setExistImageUrlList([]);
        }

        if (!createUrl) {
            resetCouponUrlData();
        }
    }, [createUrl]);

    useEffect(() => {
        if (!isEventEndTime) {
            setEventEndTimeData({
                day: 0,
                hour: 0,
                minute: 0,
            });
        }
    }, [isEventEndTime]);

    useEffect(() => {
        if (isModify && record) {
            if (
                record.web_contents_img_paths &&
                record.web_contents_img_paths.length > 0
            ) {
                getImageList(record.web_contents_img_paths);
            }
        }
    }, []);
    
    useEffect(() => {
        checkCanUsePrivateMarket();
    }, []);

    const checkCanUsePrivateMarket = async () => {
        try {
            const market = await getPrivateMarket(jgroupKey);
            // 프라이빗 마켓이 없다면 리턴한다.
            if (!market) {
                return;
            }
            // 마켓 승인이 되었다면 프라이빗 마켓을 set한다.
            if (market?.status === 'APPROVE') {
                setPrivateMarket(market);
            }
        } catch (e) {
            // 마켓 승인이 되지 않았다면 리턴한다.
            if (e.response.status === 422) {
                console.log(e.response.reason);
                return;
            }
        }
        

    };

    const getImageList = async (web_contents_img_paths) => {
        let newFileList = [];
        try {
            web_contents_img_paths.forEach((path) => {
                newFileList.push(path);
            });
            setExistImageUrlList(newFileList);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <ModalScreen className="createAndModifyCouponModal">
            <PositionController>
                {(createCouponLoading || modifyCouponLoading) && (
                    <CustomSpinner />
                )}
                {cancleModifyModal && (
                    <NoticeModal
                        multipleScreen={false}
                        multipleButton
                        title="수정 취소"
                        headerIcon="/assets/images/icon/white/alert.png"
                        primaryColor="#F6CA4F"
                        firstButtonText="아니오"
                        secondButtonText="예"
                        warningText="* 수정사항이 적용되지 않습니다."
                        body={
                            <Text color="#000">
                                수정사항을 적용하지 않고 나가시겠습니까?
                            </Text>
                        }
                        handleFirstButton={handleCancleModal}
                        handleSecondButton={handleChange}
                    />
                )}
                {isProductAddBlocked && (
                    <NoticeModal
                        multipleScreen={false}
                        title="상품 선택 불가"
                        headerIcon="/assets/images/icon/white/alert.png"
                        primaryColor="#F6CA4F"
                        firstButtonText="확인"
                        body={
                            <Text color="#000">
                                아직 온라인 판매를 위한 등록이 되지 않은 상품입니다.
                                먼저 마켓 판매 (Beta) 메뉴에서 상품의 마켓 판매가를 설정해주세요.
                            </Text>
                        }
                        handleFirstButton={()=>{
                            setIsProductAddBlocked(false);
                        }}
                        warningText={`* 마켓에서 판매중인 상품만 쿠폰 링크를 통해 구매 할 수 있습니다.`}
                    />
                )}
                <Form
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 14 }}
                    layout="horizontal"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    disabled={isCheck ? true : false}
                    // initialValues={initialValues}
                >
                    <FormContainer ref={formRef}>
                        <ModalHeader>
                            <LeftHeader>
                                {isModify ? (
                                    <Title>쿠폰 수정</Title>
                                ) : isCheck ? (
                                    <Title>쿠폰 확인</Title>
                                ) : (
                                    <Title>쿠폰 생성</Title>
                                )}
                            </LeftHeader>
                            <RightHeader>
                                <PreviewButton
                                    type="button"
                                    onClick={handlePre}
                                >
                                    미리보기
                                </PreviewButton>
                                {pbClicked && (
                                    <CouponPreviewModal
                                        handleChange={handlePre}
                                        previewName={previewName}
                                        couponType={selectedCouponType}
                                        previewGift={previewGift}
                                        previewGiftType={previewGiftType}
                                        previewPeriodType={previewPeriodType}
                                        previewPreiodDay={previewPreiodDay}
                                        previewPeriod={previewPeriod}
                                    />
                                )}
                                <Spacer size={5} />
                                <IsUse>
                                    <Text style={{ fontWeight: '500' }}>
                                        사용 여부
                                    </Text>{' '}
                                    <Form.Item
                                        name="active"
                                        initialValue={isUse}
                                    >
                                        <Switch
                                            name="active"
                                            disabled={disabled}
                                            defaultChecked={isUse}
                                        />
                                    </Form.Item>
                                    {/* <Form.Item
                    name="active"
                    initialValue={isModify && record ? record.active : true}
                  >
                    <Switch
                      name="active"
                      defaultChecked={isModify && record ? record.active : true}
                    />
                  </Form.Item> */}
                                </IsUse>
                                <Spacer size={10} />
                                <CloseButton
                                    type="button"
                                    onClick={handleModal}
                                >
                                    <CloseIcon
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/assets/images/icon/white/x.png'
                                        }
                                    />
                                </CloseButton>
                            </RightHeader>
                        </ModalHeader>
                        <FormWrapper>
                            <Spacer size={10} />
                            <ContentContainer>
                                <Text>쿠폰명</Text>
                                <Form.Item
                                    name="jgcp_name"
                                    initialValue={
                                        (isModify || isCheck) && record
                                            ? record.jgcp_name
                                            : null
                                    }
                                    rules={[
                                        {
                                            validator: (
                                                rule,
                                                value,
                                                callback,
                                            ) => {
                                                if (!value) {
                                                    callback(
                                                        '쿠폰명을 입력해주세요',
                                                    );
                                                }
                                                //  else if (
                                                //   couponList &&
                                                //   couponList.find((coupon) =>
                                                //     record
                                                //       ? record.jgcp_key !== coupon.jgcp_key &&
                                                //         coupon.jgcp_name === value
                                                //       : coupon.jgcp_name === value
                                                //   )
                                                // ) {
                                                //   callback("이미 있는 쿠폰명입니다");
                                                // }
                                                else {
                                                    callback();
                                                }
                                            },
                                        },
                                    ]}
                                >
                                    <CustomInput
                                        defaultValue={
                                            (isModify || isCheck) && record
                                                ? record.jgcp_name
                                                : null
                                        }
                                        placeholder="15자 이내로 입력해주세요"
                                        autoComplete="off"
                                        onChange={onChangeName}
                                        width="681px"
                                        height="45px"
                                        maxLength={15}
                                    ></CustomInput>
                                </Form.Item>
                            </ContentContainer>
                            <Spacer size={12} />
                            <ContentContainer>
                                <Text>쿠폰 설명</Text>

                                <Form.Item
                                    name="jgcp_reason"
                                    initialValue={
                                        (isModify || isCheck) && record
                                            ? record.jgcp_reason
                                            : null
                                    }
                                >
                                    <CustomInput
                                        defaultValue={
                                            (isModify || isCheck) && record
                                                ? record.jgcp_reason
                                                : null
                                        }
                                        placeholder="쿠폰 설명을 입력해주세요"
                                        autoComplete="off"
                                        width="681px"
                                        height="80px"
                                        type="textArea"
                                    />
                                </Form.Item>
                            </ContentContainer>
                            <ContentContainer>
                                <LeftContent />
                                <RightContent>
                                    <Text color="#EB5252">
                                        *회원들에게 노출되는 문구입니다.
                                    </Text>
                                </RightContent>
                            </ContentContainer>
                            <Divider />
                            <ContentContainer>
                                <Text>쿠폰 종류</Text>
                                <Form.Item
                                    name="coupon_type"
                                    initialValue={selectedCouponType.value}
                                >
                                    <RightContent>
                                        <Radio.Group
                                            name="coupon_type"
                                            defaultValue={
                                                selectedCouponType.value
                                            }
                                            onChange={onChangeCouponType}
                                        >
                                            <Radio
                                                value={couponTypeRadio[0].value}
                                                onClick={handleQrExchangeClick}
                                            >
                                                QR교환권{' '}
                                            </Radio>
                                            {privateMarket && <Radio
                                                value={couponTypeRadio[2].value}
                                                onClick={
                                                    handlePrivateMarketDiscountClick
                                                }
                                            >
                                                 {`센터 마켓 할인권`}
                                            </Radio>}
                                            <Radio
                                                value={couponTypeRadio[1].value}
                                                onClick={
                                                    handleMarketDiscountClick
                                                }
                                            >
                                                브랜드 마켓 할인권{' '}
                                            </Radio>
                                        </Radio.Group>
                                    </RightContent>
                                </Form.Item>
                            </ContentContainer>
                            {(selectedCouponType.value === 'MARKET_DISCOUNT' || 
                              selectedCouponType.value === 'PRIVATE_MARKET_DISCOUNT') && (
                                <React.Fragment>
                                    <Spacer />
                                    <ContentContainer>
                                        <Text>쿠폰 혜택</Text>
                                        <Form.Item
                                            name="gift_type"
                                            initialValue={
                                                selectedBenefitType.value
                                            }
                                        >
                                            <RightContent>
                                                <Radio.Group
                                                    name="gift_type"
                                                    defaultValue={
                                                        selectedBenefitType.value
                                                    }
                                                    onChange={onChangeGiftType}
                                                >
                                                    <Radio
                                                        value={
                                                            benefitRadio[0]
                                                                .value
                                                        }
                                                        onClick={
                                                            handleBenefitRateClick
                                                        }
                                                    >
                                                        할인율{' '}
                                                    </Radio>
                                                    <Radio
                                                        value={
                                                            benefitRadio[1]
                                                                .value
                                                        }
                                                        onClick={
                                                            handleBenefitAmountClick
                                                        }
                                                    >
                                                        할인액{' '}
                                                    </Radio>
                                                </Radio.Group>
                                                {/* <Popover
                          content="쿠폰을 사용할 때 센터에서 증정하고자 하는 상품을 입력해주세요.(ex.아메리카노 1잔)"
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          popupStyle={{ zIndex: 10002 }}
                        >
                          <ExplanationIcon
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/icon/select/Shape.png"
                            }
                          />
                        </Popover> */}
                                            </RightContent>
                                        </Form.Item>
                                    </ContentContainer>
                                    <Spacer />
                                    <ContentContainer
                                        style={{ alignItems: 'normal' }}
                                    >
                                        <Text color={commonTheme.font.black3}>
                                            (할인 또는 상품 증정)
                                        </Text>
                                        <RightContent>
                                            <Form.Item
                                                name="gift_value"
                                                initialValue={
                                                    (isModify || isCheck) &&
                                                    record &&
                                                    record.gift_value
                                                }
                                                rules={[
                                                    {
                                                        validator:
                                                            selectedBenefitType.value ===
                                                            'DISCOUNT_PER'
                                                                ? validateRate
                                                                : validatePrice,
                                                    },
                                                ]}
                                            >
                                                <CustomInput
                                                    placeholder={
                                                        selectedBenefitType.placeholder
                                                    }
                                                    autoComplete="off"
                                                    defaultValue={
                                                        (isModify || isCheck) &&
                                                        record &&
                                                        record.gift_value
                                                    }
                                                    width={
                                                        selectedBenefitType.inputWidth
                                                    }
                                                    height="45px"
                                                    onChange={onChangeGift}
                                                />
                                            </Form.Item>
                                            <Spacer />
                                            {selectedBenefitType.key != 2 && (
                                                <Text>
                                                    {selectedBenefitType.symbol}
                                                </Text>
                                            )}
                                        </RightContent>
                                    </ContentContainer>
                                    <Spacer />
                                    <ContentContainer>
                                        <LeftContent>
                                            <Text>적용 상품</Text>
                                            <Spacer />
                                            <Popover
                                                content="쿠폰 적용이 가능한 상품을 설정합니다. * 단건 상품 구매 시에만 쿠폰 적용이 가능합니다. 구독플랜(구독상품) 가입 시에는 쿠폰 적용이 불가합니다."
                                                getPopupContainer={(
                                                    triggerNode,
                                                ) => triggerNode.parentNode}
                                                popupStyle={{ zIndex: 10002 }}
                                            >
                                                <ExplanationIcon
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        '/assets/images/icon/select/Shape.png'
                                                    }
                                                />
                                            </Popover>
                                        </LeftContent>

                                        <Form.Item
                                            name="available_type"
                                            initialValue={
                                                selectedProduct?.value
                                            }
                                        >
                                            <RightContent>
                                                <Radio.Group
                                                    name="available_type"
                                                    defaultValue={
                                                        selectedProduct?.value
                                                    }
                                                >
                                                    <Radio
                                                        value={
                                                            productRadio[0]
                                                                .value
                                                        }
                                                        onClick={
                                                            handleTotalProductClick
                                                        }
                                                    >
                                                        전체 상품{' '}
                                                    </Radio>
                                                    <Radio
                                                        value={
                                                            productRadio[1]
                                                                .value
                                                        }
                                                        onClick={
                                                            handleSelectProductClick
                                                        }
                                                    >
                                                        선택 상품{' '}
                                                    </Radio>
                                                </Radio.Group>
                                            </RightContent>
                                        </Form.Item>
                                    </ContentContainer>
                                    {selectedProduct &&
                                        selectedProduct.key === 1 && (
                                            <ContentContainer>
                                                <LeftContent />
                                                <Form.Item
                                                    name="available_products"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            validator: () => {
                                                                if (
                                                                    selectedMarketProduct &&
                                                                    selectedMarketProduct.length >
                                                                        0
                                                                ) {
                                                                    return Promise.resolve();
                                                                } else {
                                                                    return Promise.reject(
                                                                        '상품을 선택해주세요.',
                                                                    );
                                                                }
                                                            },
                                                        },
                                                    ]}
                                                >
                                                    <RightContent
                                                        style={{
                                                            flexDirection:
                                                                'column',
                                                            alignItems:
                                                                'normal',
                                                        }}
                                                    >
                                                        <Spacer />
                                                        <CustomSelect
                                                            type="button"
                                                            onClick={
                                                                handleCheckedSelectProduct
                                                            }
                                                            isSelected={
                                                                selectedMarketProduct
                                                            }
                                                        >
                                                            {selectedMarketProduct.length ===
                                                            0 ? (
                                                                <Text color="#7c7c7c">
                                                                    상품
                                                                    선택하기
                                                                </Text>
                                                            ) : selectedMarketProduct.length ===
                                                              1 ? (
                                                                <Text color="#fff">
                                                                    {
                                                                        selectedMarketProduct[0]?.product_name || selectedMarketProduct[0]?.name
                                                                    }
                                                                </Text>
                                                            ) : (
                                                                <Text color="#fff">
                                                                    {
                                                                        selectedMarketProduct[0]
                                                                            ?.product_name || selectedMarketProduct[0]?.name
                                                                    }{' '}
                                                                    외&nbsp;
                                                                    {selectedMarketProduct.length -
                                                                        1}
                                                                    건
                                                                </Text>
                                                            )}
                                                            <img
                                                                src={
                                                                    process.env
                                                                        .PUBLIC_URL +
                                                                    '/assets/images/icon/select/toggle_down.png'
                                                                }
                                                                style={{
                                                                    width: 12,
                                                                    height: 6,
                                                                }}
                                                            />
                                                        </CustomSelect>
                                                        <div
                                                            style={{
                                                                overflow:
                                                                    'hidden',
                                                                height: checkedSelectProduct
                                                                    ? '400px'
                                                                    : '0px',
                                                                position:
                                                                    'absolute',
                                                                transition:
                                                                    '0.2s',
                                                                top: '62px',
                                                                zIndex: 100,
                                                            }}
                                                        >
                                                            <SelectProductWrapper
                                                                enabled={
                                                                    checkedSelectProduct
                                                                }
                                                            >
                                                                <SelectProductContainer>
                                                                    <Header
                                                                        style={{
                                                                            height: 'auto',
                                                                        }}
                                                                    >
                                                                        <Tabs
                                                                            defaultActiveKey="market"
                                                                            onChange={
                                                                                handleSelectPlatformTab
                                                                            }
                                                                            className="platform-tab"
                                                                        >
                                                                            <TabPane
                                                                                tab={selectedCouponType.value === 'PRIVATE_MARKET_DISCOUNT' 
                                                                                    ? privateMarket?.name : "마켓"}
                                                                                key="market"
                                                                            />
                                                                        </Tabs>
                                                                    </Header>
                                                                    <Spacer
                                                                        size={8}
                                                                    />
                                                                    <Content
                                                                        style={{
                                                                            overflow:
                                                                                'visible',
                                                                        }}
                                                                    >
                                                                        {' '}
                                                                        <Tabs
                                                                            defaultActiveKey="TICKET_LESSON"
                                                                            onChange={
                                                                                handleSelectMarketProductTab
                                                                            }
                                                                        >
                                                                            {selectedCouponType.value === 'PRIVATE_MARKET_DISCOUNT' ? privateMarketProductTypes?.map(
                                                                                (
                                                                                    tab,
                                                                                    index,
                                                                                ) => {
                                                                                    return (
                                                                                        <TabPane
                                                                                            tab={
                                                                                                tab.label
                                                                                            }
                                                                                            key={
                                                                                                tab.key
                                                                                            }
                                                                                        ></TabPane>
                                                                                    );
                                                                                },
                                                                            ) : items.map(
                                                                                (
                                                                                    tab,
                                                                                    index,
                                                                                ) => {
                                                                                    return (
                                                                                        <TabPane
                                                                                            tab={
                                                                                                tab.label
                                                                                            }
                                                                                            key={
                                                                                                tab.key
                                                                                            }
                                                                                        ></TabPane>
                                                                                    );
                                                                                },
                                                                            )}
                                                                        </Tabs>
                                                                        <Spacer />
                                                                        {formRef.current && selectedCouponType.value === 'PRIVATE_MARKET_DISCOUNT' ? (
                                                                            <SelectPrivateMarketProductView
                                                                                formRef={
                                                                                    formRef
                                                                                }
                                                                                type={
                                                                                    selectMarketProductTab
                                                                                }
                                                                                handleSelectedMarketProduct={
                                                                                    handleSelectedMarketProduct
                                                                                }
                                                                                selectedMarketProduct={
                                                                                    selectedMarketProduct
                                                                                }
                                                                                isNotCreate={
                                                                                    isModify ||
                                                                                    isCheck
                                                                                }
                                                                                setIsProductAddBlocked={
                                                                                    setIsProductAddBlocked
                                                                                }
                                                                            />
                                                                        ) : <SelectProductMenu
                                                                               formRef={
                                                                                   formRef
                                                                               }
                                                                               type={
                                                                                   selectMarketProductTab
                                                                               }
                                                                               selectPlatformTab={
                                                                                   selectPlatformTab
                                                                               }
                                                                               handleSelectedMarketProduct={
                                                                                   handleSelectedMarketProduct
                                                                               }
                                                                               selectedMarketProduct={
                                                                                   selectedMarketProduct
                                                                               }
                                                                               isNotCreate={
                                                                                   isModify ||
                                                                                   isCheck
                                                                               }
                                                                        />}
                                                                        {/* {selectPlatformTab ===
                                                                        'crm' ? (
                                                                            <div
                                                                                style={{
                                                                                    width: '100%',
                                                                                    height: '100%',
                                                                                    display:
                                                                                        'flex',
                                                                                    alignItems:
                                                                                        'center',
                                                                                    justifyContent:
                                                                                        'center',
                                                                                }}
                                                                            >
                                                                                {' '}
                                                                                <Text
                                                                                    size={
                                                                                        24
                                                                                    }
                                                                                >
                                                                                    서비스
                                                                                    준비
                                                                                    중입니다.
                                                                                </Text>
                                                                            </div>
                                                                        ) : (
                                                                            <React.Fragment>
                                                                                <Tabs
                                                                                    defaultActiveKey="TICKET_MEMBER"
                                                                                    onChange={
                                                                                        handleSelectMarketProductTab
                                                                                    }
                                                                                >
                                                                                    {items.map(
                                                                                        (
                                                                                            tab,
                                                                                            index,
                                                                                        ) => {
                                                                                            return (
                                                                                                <TabPane
                                                                                                    tab={
                                                                                                        tab.label
                                                                                                    }
                                                                                                    key={
                                                                                                        tab.key
                                                                                                    }
                                                                                                ></TabPane>
                                                                                            );
                                                                                        },
                                                                                    )}
                                                                                </Tabs>
                                                                                <Spacer />
                                                                                {formRef.current && (
                                                                                    <SelectProductMenu
                                                                                        formRef={
                                                                                            formRef
                                                                                        }
                                                                                        type={
                                                                                            selectMarketProductTab
                                                                                        }
                                                                                        handleSelectedMarketProduct={
                                                                                            handleSelectedMarketProduct
                                                                                        }
                                                                                        selectedMarketProduct={
                                                                                            selectedMarketProduct
                                                                                        }
                                                                                        isNotCreate={
                                                                                            isModify ||
                                                                                            isCheck
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            </React.Fragment>
                                                                        )} */}
                                                                    </Content>
                                                                </SelectProductContainer>
                                                            </SelectProductWrapper>
                                                        </div>
                                                        {/* {selectedMarketProduct?.length <= 0 &&
                            isPressCreateAndmodifyBtn && (
                              <div>
                                <p style={{ color: "#ff4d4f", fontSize: 11 }}>
                                  상품을 선택해주세요.
                                </p>
                              </div>
                            )} */}
                                                    </RightContent>
                                                </Form.Item>
                                            </ContentContainer>
                                        )}
                                </React.Fragment>
                            )}

                            <Divider />
                            <ContentContainer>
                                <Text>유효기간</Text>
                                <Form.Item
                                    name="period_type"
                                    initialValue={
                                        (isModify || isCheck) && record
                                            ? record.period_type
                                            : expirationRadio[0].value
                                    }
                                >
                                    <RightContent>
                                        <Radio.Group
                                            name="period_type"
                                            defaultValue={
                                                (isModify || isCheck) && record
                                                    ? record.period_type
                                                    : expirationRadio[0].value
                                            }
                                            onChange={onChangePeriodType}
                                        >
                                            <Radio
                                                value={expirationRadio[0].value}
                                                onClick={handleIssueDateClick}
                                            >
                                                발행일 기준{' '}
                                            </Radio>
                                            <Radio
                                                value={expirationRadio[1].value}
                                                onClick={handleDurationClick}
                                            >
                                                기간 설정{' '}
                                            </Radio>
                                        </Radio.Group>
                                    </RightContent>
                                </Form.Item>
                            </ContentContainer>
                            <Spacer />
                            <ContentContainer>
                                <LeftContent />
                                {selectedExpiration.key == 0 ? (
                                    <RightContent>
                                        <Text>발행일로부터</Text>
                                        <Spacer />
                                        <Form.Item
                                            name="jgcp_preiod_day"
                                            initialValue={preiodDay}
                                            rules={[
                                                {
                                                    validator: validatePeriod,
                                                },
                                            ]}
                                        >
                                            <CustomInput
                                                className="jgcp_preiod_day"
                                                name="jgcp_preiod_day"
                                                placeholder="숫자"
                                                width="58px"
                                                height="45px"
                                                textAlign="center"
                                                autoComplete="off"
                                                defaultValue={preiodDay}
                                                onChange={onChangePreiodDay}
                                                value={preiodDay}
                                            />
                                        </Form.Item>
                                        <Spacer />
                                        <Text>일 이내 사용 가능합니다.</Text>
                                    </RightContent>
                                ) : (
                                    <RightContent style={{ height: '45px' }}>
                                        <ConfigProvider locale={locale}>
                                            <Form.Item
                                                name="jgcp_specific_period"
                                                initialValue={period}
                                                rules={[
                                                    {
                                                        validator:
                                                            validateDateRange,
                                                    },
                                                ]}
                                            >
                                                <RangePicker
                                                    getPopupContainer={(
                                                        triggerNode,
                                                    ) => triggerNode.parentNode}
                                                    placement="topLeft"
                                                    popupStyle={{
                                                        zIndex: 10003,
                                                        top: '-300px',
                                                    }}
                                                    placeholder={[
                                                        '시작일',
                                                        '종료일',
                                                    ]}
                                                    style={{
                                                        width: '250px',
                                                        height: '50px',
                                                    }}
                                                    defaultValue={period}
                                                    value={period}
                                                    onCalendarChange={(val) =>
                                                        setPeriod(val)
                                                    }
                                                    onChange={(val) =>
                                                        setPreviewPeriod(val)
                                                    }
                                                    // onChange={onChangePeriodStart}
                                                />
                                            </Form.Item>
                                            {/* <Form.Item
                        name="jgcp_period_start"
                        initialValue={periodStart}
                        rules={[
                          {
                            required: true,
                            message: "시작일을 입력해주세요",
                          },
                        ]}
                      >
                        <DatePicker
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          popupStyle={{ zIndex: 10003 }}
                          placeholder="시작일 설정"
                          style={{ width: "167px", height: "45px" }}
                          defaultValue={periodStart}
                          value={periodStart}
                          onChange={onChangePeriodStart}
                        />
                      </Form.Item>

                      <Spacer />
                      <Text>~</Text>
                      <Spacer />
                      <Form.Item
                        name="jgcp_period_end"
                        initialValue={periodEnd}
                        rules={[
                          {
                            required: true,
                            message: "종료일을 입력해주세요",
                          },
                        ]}
                      >
                        <DatePicker
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          popupStyle={{ zIndex: 10003 }}
                          placeholder="종료일 설정"
                          style={{ width: "167px", height: "45px" }}
                          defaultValue={periodEnd}
                          value={periodEnd}
                          onChange={onChangePeriodEnd}
                          disabledDate={(current) =>
                            current < periodStart.endOf("day")
                          }
                        />
                      </Form.Item> */}
                                        </ConfigProvider>
                                    </RightContent>
                                )}
                            </ContentContainer>
                            {/* <ContentContainer>
                <LeftContent>
                  <Text>유효기간 알림</Text>
                </LeftContent>

                <Form.Item name="expired_alarm_day">
                  <RightContent>
                    <Checkbox
                      onChange={onNoneCheckChange}
                      checked={checkedList.indexOf("none") !== -1}
                    >
                      알림 없음
                    </Checkbox>
                    <Checkbox.Group
                      options={expirationCheckBox.filter(
                        (item) => item.value !== "none"
                      )}
                      value={checkedList.filter((item) => item !== "none")}
                      onChange={onChange}
                    />
                  </RightContent>
                </Form.Item>
                <Form.Item name="???">
                  <RightContent>
                    <Select
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      style={{
                        zIndex: 10002,
                        width: "329px",
                        height: "45px",
                      }}
                      defaultValue={expirationNoticeSelect[0].value}
                      placement="bottomLeft"
                    >
                      {expirationNoticeSelect.map((notice) => (
                        <Select.Option value={notice.value} key={notice.key}>
                          {notice.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </RightContent>
                </Form.Item>
              </ContentContainer> */}
                            <Divider />
                            <ContentContainer>
                                <LeftContent>
                                    <Text>수량</Text>
                                </LeftContent>

                                <Form.Item
                                    name="jgcp_limited"
                                    initialValue={
                                        record && record.jgcp_limited
                                            ? countRadio[1].value
                                            : countRadio[0].value
                                    }
                                >
                                    <RightContent>
                                        <Radio.Group
                                            name="jgcp_limited"
                                            defaultValue={
                                                record && record.jgcp_limited
                                                    ? countRadio[1].value
                                                    : countRadio[0].value
                                            }
                                        >
                                            <Radio
                                                value={countRadio[0].value}
                                                onClick={
                                                    handleCountInfiniteClick
                                                }
                                            >
                                                무제한{' '}
                                            </Radio>
                                            <Radio
                                                value={countRadio[1].value}
                                                onClick={handleCountLimitClick}
                                            >
                                                한정 수량{' '}
                                            </Radio>
                                        </Radio.Group>
                                    </RightContent>
                                </Form.Item>
                            </ContentContainer>
                            <Spacer />
                            {selectedCount.key === 1 && (
                                <ContentContainer>
                                    <LeftContent />
                                    <Form.Item
                                        name="jgcp_limit_count"
                                        initialValue={
                                            (isModify || isCheck) && record
                                                ? record.jgcp_limit_count
                                                : null
                                        }
                                        rules={[
                                            {
                                                validator: validateCount,
                                            },
                                        ]}
                                    >
                                        <RightContent>
                                            <CustomInput
                                                width="99px"
                                                height="45px"
                                                placeholder="숫자 입력"
                                                min={1}
                                                autoComplete="off"
                                                defaultValue={
                                                    (isModify || isCheck) &&
                                                    record
                                                        ? record.jgcp_limit_count
                                                        : null
                                                }
                                            />
                                            <Spacer />
                                            <Text>장(매)</Text>
                                        </RightContent>
                                    </Form.Item>
                                </ContentContainer>
                            )}
                            <Spacer />
                            <Divider />
                            <div
                                style={{
                                    // display: "flex",
                                    overflow: 'auto',
                                }}
                            >
                                <ContentContainer>
                                    <LeftContent>
                                        <Text>쿠폰 URL 생성</Text>
                                    </LeftContent>
                                    <RightContent>
                                        <Text>사용 여부</Text>
                                        <Spacer size={3} />
                                        <Form.Item
                                            name="is_share_coupon_url"
                                            initialValue={createUrl}
                                        >
                                            <Switch
                                                name="is_share_coupon_url"
                                                defaultChecked={createUrl}
                                                onChange={() => {
                                                    setCreateUrl(!createUrl);
                                                }}
                                            />
                                        </Form.Item>
                                    </RightContent>
                                </ContentContainer>
                                <Spacer />
                                <ContentContainer>
                                    <LeftContent />
                                    <RightContent>
                                        <Text
                                            fontWeight="500"
                                            color="#979797"
                                        >{`상세 이미지 등록 (${
                                            isModify
                                                ? existImageUrlList.length
                                                : imageUrlList.length
                                        }/10)`}</Text>
                                    </RightContent>
                                </ContentContainer>
                                <Spacer />
                                <ContentContainer>
                                    <LeftContent />
                                    <RightContent>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                            }}
                                        >
                                            <div>
                                                {existImageUrlList &&
                                                    existImageUrlList.length >
                                                        0 &&
                                                    existImageUrlList.map(
                                                        (path, index) => {
                                                            return (
                                                                <ImageBox
                                                                    key={index}
                                                                    src={`${config.broj_contents_url}/open-coupon/${path}`}
                                                                    id={index}
                                                                    deleteImage={
                                                                        deleteExistImage
                                                                    }
                                                                />
                                                            );
                                                        },
                                                    )}
                                                {imageUrlList &&
                                                    imageUrlList.length > 0 &&
                                                    imageUrlList.map(
                                                        (item, index) => {
                                                            return (
                                                                <ImageBox
                                                                    key={index}
                                                                    src={item}
                                                                    id={index}
                                                                    deleteImage={
                                                                        deleteImage
                                                                    }
                                                                />
                                                            );
                                                        },
                                                    )}
                                            </div>
                                            <Form.Item>
                                                <div>
                                                    <div
                                                        onClick={() => {
                                                            // if (!createUrl) {
                                                            //   return;
                                                            // }
                                                            if (
                                                                imageList.length >=
                                                                10
                                                            ) {
                                                                message.warning(
                                                                    {
                                                                        content:
                                                                            '이미지 등록은 최대 10장 입니다.',
                                                                        style: {
                                                                            marginTop:
                                                                                '90vh',
                                                                        },
                                                                        duration: 1,
                                                                    },
                                                                );
                                                                return;
                                                            } else {
                                                                imageInputRef.current.click();
                                                            }
                                                        }}
                                                        style={{
                                                            width: 90,
                                                            height: 90,
                                                            border: `1px solid ${'#fff'}`,
                                                            borderRadius: 5,
                                                            position:
                                                                'relative',
                                                            cursor: 'pointer',
                                                            // display: "flex",
                                                            display:
                                                                'inline-flex',
                                                            justifyContent:
                                                                'center',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Icon
                                                            style={{
                                                                width: '15px',
                                                                height: '15px',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'center',
                                                            }}
                                                            src={
                                                                process.env
                                                                    .PUBLIC_URL +
                                                                'assets/images/icon/add_icon.png'
                                                            }
                                                        />

                                                        <input
                                                            ref={imageInputRef}
                                                            onChange={
                                                                onFileChange
                                                            }
                                                            accept="image/*"
                                                            style={{
                                                                // padding: 0,
                                                                width: '100%',
                                                                height: '100%',
                                                                display: 'none',
                                                                // padding: 30,
                                                                // background: "none",
                                                            }}
                                                            type="file"
                                                            multiple
                                                            max={10}
                                                        />
                                                    </div>
                                                </div>
                                            </Form.Item>
                                        </div>
                                    </RightContent>
                                </ContentContainer>
                                <Spacer size={12} />
                                <ContentContainer>
                                    <LeftContent />
                                    <RightContent>
                                        <Text
                                            color={
                                                !createUrl ? '#979797' : '#fff'
                                            }
                                        >
                                            이벤트 마감시간 설정
                                        </Text>
                                        <Spacer size={3} />
                                        <Form.Item
                                            initialValue={isEventEndTime}
                                        >
                                            <Switch
                                                disabled={
                                                    createUrl ? false : true
                                                }
                                                checked={isEventEndTime}
                                                onChange={() => {
                                                    setIsEventEndTime(
                                                        !isEventEndTime,
                                                    );
                                                }}
                                            />
                                        </Form.Item>
                                    </RightContent>
                                </ContentContainer>
                                <ContentContainer>
                                    <LeftContent />
                                    <RightContent>
                                        <Text color="#979797" size={12}>
                                            마감시간을 설정하면 이벤트 마감
                                            시간이 생동감있게 노출됩니다.
                                        </Text>
                                    </RightContent>
                                </ContentContainer>
                                <Spacer />
                                <ContentContainer>
                                    <LeftContent />
                                    <RightContent>
                                        <Form.Item>
                                            <Dropdown
                                                disabled={
                                                    createUrl && isEventEndTime
                                                        ? false
                                                        : true
                                                }
                                                getPopupContainer={(
                                                    triggerNode,
                                                ) => triggerNode.parentNode}
                                                popupStyle={{ zIndex: 10600 }}
                                                trigger={['click']}
                                                overlay={days}
                                                // position="relative"
                                                overlayStyle={{
                                                    position: 'absolute',
                                                }}
                                                style={{ height: '100%' }}
                                            >
                                                <div
                                                    style={{
                                                        background:
                                                            createUrl &&
                                                            isEventEndTime
                                                                ? '#4a4a4a'
                                                                : '#3a3a3a',
                                                        padding: '12px',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        width: 70,
                                                        justifyContent:
                                                            'space-between',
                                                        // marginLeft: "10px",
                                                        // cursor: "pointer",
                                                        // position: "relative",
                                                        height: '100%',
                                                        borderRadius: '5px',
                                                    }}
                                                >
                                                    <Text
                                                        color={
                                                            createUrl &&
                                                            isEventEndTime
                                                                ? '#fff'
                                                                : '#979797'
                                                        }
                                                    >
                                                        {eventEndTimeData.day}
                                                    </Text>
                                                    <Icon
                                                        width="12px"
                                                        height="6px"
                                                        src={
                                                            process.env
                                                                .PUBLIC_URL +
                                                            '/assets/images/icon/select/toggle_down.png'
                                                        }
                                                    />
                                                </div>
                                            </Dropdown>
                                        </Form.Item>
                                        <Spacer size={3} />
                                        <Text>일</Text>
                                        <Spacer size={5} />
                                        <Form.Item>
                                            <Dropdown
                                                disabled={
                                                    createUrl && isEventEndTime
                                                        ? false
                                                        : true
                                                }
                                                getPopupContainer={(
                                                    triggerNode,
                                                ) => triggerNode.parentNode}
                                                popupStyle={{ zIndex: 10500 }}
                                                trigger={['click']}
                                                overlay={hours}
                                                // position="relative"
                                                overlayStyle={{
                                                    position: 'absolute',
                                                }}
                                                style={{ height: '100%' }}
                                            >
                                                <div
                                                    style={{
                                                        background:
                                                            createUrl &&
                                                            isEventEndTime
                                                                ? '#4a4a4a'
                                                                : '#3a3a3a',
                                                        padding: '12px',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        width: 70,
                                                        justifyContent:
                                                            'space-between',
                                                        // marginLeft: "10px",
                                                        // cursor: "pointer",
                                                        // position: "relative",
                                                        height: '100%',
                                                        borderRadius: '5px',
                                                    }}
                                                >
                                                    <Text
                                                        color={
                                                            createUrl &&
                                                            isEventEndTime
                                                                ? '#fff'
                                                                : '#979797'
                                                        }
                                                    >
                                                        {eventEndTimeData.hour}
                                                    </Text>
                                                    <Icon
                                                        width="12px"
                                                        height="6px"
                                                        src={
                                                            process.env
                                                                .PUBLIC_URL +
                                                            '/assets/images/icon/select/toggle_down.png'
                                                        }
                                                    />
                                                </div>
                                            </Dropdown>
                                        </Form.Item>
                                        <Spacer size={3} />
                                        <Text>시간</Text>
                                        <Spacer size={5} />
                                        <Form.Item>
                                            <Dropdown
                                                disabled={
                                                    createUrl && isEventEndTime
                                                        ? false
                                                        : true
                                                }
                                                getPopupContainer={(
                                                    triggerNode,
                                                ) => triggerNode.parentNode}
                                                popupStyle={{ zIndex: 10500 }}
                                                trigger={['click']}
                                                overlay={minutes}
                                                // position="relative"
                                                overlayStyle={{
                                                    position: 'absolute',
                                                }}
                                                style={{ height: '100%' }}
                                            >
                                                <div
                                                    style={{
                                                        background:
                                                            createUrl &&
                                                            isEventEndTime
                                                                ? '#4a4a4a'
                                                                : '#3a3a3a',
                                                        padding: '12px',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        width: 70,
                                                        justifyContent:
                                                            'space-between',
                                                        // marginLeft: "10px",
                                                        // cursor: "pointer",
                                                        // position: "relative",
                                                        height: '100%',
                                                        borderRadius: '5px',
                                                    }}
                                                >
                                                    <Text
                                                        color={
                                                            createUrl &&
                                                            isEventEndTime
                                                                ? '#fff'
                                                                : '#979797'
                                                        }
                                                    >
                                                        {
                                                            eventEndTimeData.minute
                                                        }
                                                    </Text>
                                                    <Icon
                                                        width="12px"
                                                        height="6px"
                                                        src={
                                                            process.env
                                                                .PUBLIC_URL +
                                                            '/assets/images/icon/select/toggle_down.png'
                                                        }
                                                    />
                                                </div>
                                            </Dropdown>
                                        </Form.Item>
                                        <Spacer size={3} />
                                        <Text>분</Text>
                                    </RightContent>
                                </ContentContainer>
                            </div>
                            <Spacer size={12} />
                            {/* <ContentContainer>
                <LeftContent>
                  <Text>이미지 추가</Text>
                </LeftContent>
                <RightContent>
                  <div>
                    <Upload action="/upload.do" listType="text">
                      <div>이미지 선택하기</div>
                    </Upload>
                  </div>
                </RightContent>
              </ContentContainer>
              <Spacer />
              <ContentContainer>
                <LeftContent />
                <RightContent>
                  <Text color={commonTheme.font.black3}>
                    * 1440*1440px 이하 파일만 첨부 가능 <Spacer size={3} />* 각
                    300KB (최대2장), jpg/jpeg 만 첨부 가능
                  </Text>
                </RightContent>
              </ContentContainer> */}
                        </FormWrapper>
                        {isCheck ? (
                            <ActionButtonGroup>
                                <CheckButton
                                    type="button"
                                    onClick={handleChange}
                                >
                                    확인
                                </CheckButton>
                            </ActionButtonGroup>
                        ) : (
                            <ActionButtonGroup>
                                <CustomButton
                                    background={'#7c7c7c'}
                                    onClick={handleModal}
                                    text="취소"
                                />
                                {isModify && record ? (
                                    <Form.Item>
                                        <CustomButton
                                            background={'#fa6400'}
                                            htmlType="submit"
                                            text="수정하기"
                                        />
                                    </Form.Item>
                                ) : (
                                    <Form.Item>
                                        <CustomButton
                                            background={'#fa6400'}
                                            htmlType="submit"
                                            text="생성하기"
                                        />
                                    </Form.Item>
                                )}
                                {createCompleteModal && (
                                    <NoticeModal
                                        multipleScreen={false}
                                        multipleButton={false}
                                        title="쿠폰 생성 완료"
                                        headerIcon="/assets/images/icon/white/v.png"
                                        primaryColor="#78B2A0"
                                        firstButtonText="확인"
                                        body={
                                            <Text color="#000">
                                                쿠폰 생성이 완료되었습니다.
                                            </Text>
                                        }
                                        warningText="* 쿠폰 목록에서 다시 수정 가능합니다."
                                        handleFirstButton={handleChange}
                                    />
                                )}
                                {modifyCompleteModal && (
                                    <NoticeModal
                                        multipleScreen={false}
                                        multipleButton={false}
                                        title="쿠폰 수정 완료"
                                        headerIcon="/assets/images/icon/white/v.png"
                                        primaryColor="#78B2A0"
                                        firstButtonText="확인"
                                        body={
                                            <Text color="#000">
                                                쿠폰 수정이 완료되었습니다.
                                            </Text>
                                        }
                                        warningText="* 쿠폰 목록에서 다시 수정 가능합니다."
                                        handleFirstButton={handleChange}
                                    />
                                )}
                            </ActionButtonGroup>
                        )}
                    </FormContainer>
                </Form>
            </PositionController>
        </ModalScreen>
    );
};

const CheckButton = styled.button`
    width: 900px;
    height: 56px;
    background-color: #fa6400;
    color: #fff;
    font-size: 20px;
    line-height: 29px;
    border: 0;
`;

const antMoveDownIn = keyframes`
  0% {
    transform-origin: 0 0;
    transform: translateY(0);
    opacity: 1;
  }
  100%{
    transform-origin: 0 0;
    transform: translateY(-100%);
    opacity: 0;
  }
`;

const antMoveDownOut = keyframes`
  0% {
    transform-origin: 0 0;
    transform: translateY(-100%);
    opacity: 0;
  }
  100%{
    transform-origin: 0 0;
    transform: translateY(0);
    opacity: 1;
  }
`;

const SpeechBubble = styled.div`
    position: absolute;
    width: 222px;
    height: 50px;
    border-radius: 4px;
    background: ${commonTheme.background.black10};
    padding: 8px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
`;

const CustomSelect = styled.div`
    width: 535px;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #4a4a4a;
    border-radius: 5px;
    border: none;
    box-sizing: border-box;
    margin: 0;
    padding: 12px;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    cursor: pointer;
`;

const SelectProductWrapper = styled.div`
    position: relative;
    height: 0;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    animation-name: ${(props) =>
        props.enabled ? antMoveDownOut : antMoveDownIn};
`;

const SelectProductContainer = styled(Layout)`
    background: #4a4a4a;
    padding: 12px;
    width: 535px;
    height: 404px;
`;

const GiftExplanationTip = styled.div`
    position: relative;
    right: 100px;
    bottom: 70px;
`;

const Tip = styled.p`
    width: 100%;
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: -0.670707px;
    margin: 0;
    color: #000000;
`;

const ExplanationIcon = styled.img`
    width: 13.33px;
    height: 13.33px;
    :hover {
        cursor: pointer;
    }
`;

const ExplanationIcon2 = styled.img`
    width: 13.33px;
    height: 13.33px;
    :hover {
        cursor: pointer;
    }
`;

const LeftContent = styled.div`
    display: flex;
    align-items: center;
`;

const RightContent = styled.div`
    width: 681px;
    display: flex;
    align-items: center;
`;

const CloseIcon = styled.img`
    position: absolute;
    top: 24px;
    right: 24px;
    width: 24px;
    height: 24px;
`;

const ContentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ModalHeader = styled.div`
    position: sticky;
    top: 0;
    background: ${commonTheme.background.black4};
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 24px;
    padding-bottom: 12px;
    z-index: 100;
`;

const LeftHeader = styled.div`
    display: flex;
    align-items: center;
`;

const RightHeader = styled.div`
    display: flex;
    align-items: center;
`;

const PreviewButton = styled.button`
    width: 76px;
    height: 33px;
    background: ${commonTheme.background.black7};
    border: none;
    border-radius: 3px;
    padding: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: ${commonTheme.font.black5};
    :hover {
        cursor: pointer;
    }
`;

const IsUse = styled.div`
    width: 115px;
    height: 33px;
    background: ${commonTheme.background.black7};
    border: none;
    border-radius: 3px;
    padding: 7px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 14px;
    color: ${commonTheme.font.black5};
    :hover {
        cursor: pointer;
    }
`;

const FormContainer = styled.div`
    position: relative;
    background: ${commonTheme.background.black4};
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    width: 900px;
    height: 671px;
`;

const FormWrapper = styled.div`
    width: 100%;
    height: calc(100% - 125px);
    overflow: scroll;
    padding: 30px;
    padding-top: 0;
    padding-bottom: 0;
`;

export default CreateAndModifyCouponModal;
