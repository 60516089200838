import { Checkbox, Divider, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { Spacer, Text } from "../styles/commonStyle";
import "../styles/SelectProductMenu.css";

/** 선택 상품 SELECT 버튼을 클릭했을 때, 나오는 상품 선택 모달창 */
const SelectPrivateMarketProductView = ({
  formRef,
  type,
  selectedMarketProduct,
  handleSelectedMarketProduct,
  isNotCreate,
  setIsProductAddBlocked,
}) => {
  const [checkedList, setCheckedList] = useState([]);
  const containerRef = useRef(null);
  const privateMarketProducts = useSelector(
    (state) => state.couponReducer.privateMarketProducts
  );
  const [isSelect, setIsSelect] = useState(false);
  const [filterdProducts, setFilteredProducts] = useState(null);
  // 각 타입별 상품 state
  const [entryTickets, setEntryTickets] = useState(null);
  const [reservationTickets, setReservationTickets] = useState(null);
  const [facilityTickets, setFacilityTickets] = useState(null);
  const [lockerTickets, setLockerTickets] = useState(null);
  const [sportsTickets, setSportsTickets] = useState(null);
  // 각 타입별 선택된 상품 state
  const [selectedEntryTickets, setSelectedEntryTickets] = useState([]);
  const [selectedReservationTickets, setSelectedReservationTickets] = useState(
    []
  );
  const [selectedFacilityTickets, setSelectedFacilityTickets] = useState([]);
  const [selectedLockerTickets, setSelectedLockerTickets] = useState([]);
  const [selectedSportsTickets, setSelectedSportsTickets] = useState([]);

  const handleCheckAll = (e) => {
    if (!isSelect) {
      setIsSelect((isSelect) => !isSelect);
    }

    // 선택된 상품 있는지 검증 함수
    const hasSelectedItems = () => {
      switch (type) {
        case "LESSON_ENTRY":
          return selectedEntryTickets.length > 0;
        case "LESSON_CLASS":
          return selectedReservationTickets.length > 0;
        case "LESSON_FACILITY":
          return selectedFacilityTickets.length > 0;
        case "LOCKER":
          return selectedLockerTickets.length > 0;
        case "SPORTS":
          return selectedSportsTickets.length > 0;
        default:
          return false;
      }
    };

    if (hasSelectedItems()) {
      switch (type) {
        case "LESSON_ENTRY":
          setSelectedEntryTickets([]);
          break;
        case "LESSON_CLASS":
          setSelectedReservationTickets([]);
          break;
        case "LESSON_FACILITY":
          setSelectedFacilityTickets([]);
          break;
        case "LOCKER":
          setSelectedLockerTickets([]);
          break;
        case "SPORTS":
          setSelectedSportsTickets([]);
          break;
      }
    } else {
      switch (type) {
        case "LESSON_ENTRY":
          setSelectedEntryTickets(entryTickets.filter(ticket => ticket?.market_options !== null));
          break;
        case "LESSON_CLASS":
          setSelectedReservationTickets(reservationTickets.filter(ticket => ticket?.market_options !== null));
          break;
        case "LESSON_FACILITY":
          setSelectedFacilityTickets(facilityTickets.filter(ticket => ticket?.market_options !== null));
          break;
        case "LOCKER":
          setSelectedLockerTickets(lockerTickets.filter(ticket => ticket?.market_options !== null));
          break;
        case "SPORTS":
          setSelectedSportsTickets(sportsTickets.filter(ticket => ticket?.market_options !== null));
          break;
      }
    }
  };

  const handleCheckItem = (item) => {
    if (!isSelect) {
      setIsSelect((isSelect) => !isSelect);
    }
    switch (type) {
      case "LESSON_ENTRY":
        setSelectedEntryTickets((prevCheckedList) => {
          const isChecked = prevCheckedList.find(
            (product) => product.key === item.key
          );
          if (isChecked) {
            return prevCheckedList.filter((prev) => prev.key !== item.key);
          } else {
            return [...prevCheckedList, item];
          }
        });
        break;
      case "LESSON_CLASS":
        setSelectedReservationTickets((prevCheckedList) => {
          const isChecked = prevCheckedList.find(
            (product) => product.key === item.key
          );
          if (isChecked) {
            return prevCheckedList.filter((prev) => prev.key !== item.key);
          } else {
            return [...prevCheckedList, item];
          }
        });
        break;
      case "LESSON_FACILITY":
        setSelectedFacilityTickets((prevCheckedList) => {
          const isChecked = prevCheckedList.find(
            (product) => product.key === item.key
          );
          if (isChecked) {
            return prevCheckedList.filter((prev) => prev.key !== item.key);
          } else {
            return [...prevCheckedList, item];
          }
        });
        break;
      case "LOCKER":
        setSelectedLockerTickets((prevCheckedList) => {
          const isChecked = prevCheckedList.find(
            (product) => product.key === item.key
          );
          if (isChecked) {
            return prevCheckedList.filter((prev) => prev.key !== item.key);
          } else {
            return [...prevCheckedList, item];
          }
        });
        break;
      case "SPORTS":
        setSelectedSportsTickets((prevCheckedList) => {
          const isChecked = prevCheckedList.find(
            (product) => product.key === item.key
          );
          if (isChecked) {
            return prevCheckedList.filter((prev) => prev.key !== item.key);
          } else {
            return [...prevCheckedList, item];
          }
        });
        break;
    }
  };

  /** 상품 타입별로 필터링 하여 set */
  useEffect(() => {
    // 생성시
    setEntryTickets(
      privateMarketProducts.filter(
        (product) => product.type === "LESSON" && product.sub_type === "ENTRY"
      )
    );
    setReservationTickets(
      privateMarketProducts.filter(
        (product) => product.type === "LESSON" && product.sub_type === "CLASS"
      )
    );
    setFacilityTickets(
      privateMarketProducts.filter(
        (product) =>
          product.type === "LESSON" &&
          (product.sub_type === "FACILITY_GOLF" ||
            product.sub_type === "FACILITY_LIBRARY")
      )
    );
    setLockerTickets(
      privateMarketProducts.filter((product) => product.type === "LOCKER")
    );
    setSportsTickets(
      privateMarketProducts.filter((product) => product.type === "SPORTS")
    );
    // 수정시
    if (isNotCreate) {
      setSelectedEntryTickets(
        selectedMarketProduct.filter((product) => product?.type === "LESSON" && product?.sub_type === "ENTRY")
      );
      setSelectedReservationTickets(
        selectedMarketProduct.filter((product) => product?.type === "LESSON" && product?.sub_type === "CLASS")
      );
      setSelectedFacilityTickets(
        selectedMarketProduct.filter(
          (product) =>
            product?.type === "LESSON" &&
            (product?.sub_type === "FACILITY_GOLF" ||
            product?.sub_type === "FACILITY_LIBRARY")
        )
      );
      setSelectedLockerTickets(
        selectedMarketProduct.filter((product) => product?.type === "LOCKER")
      );
      setSelectedSportsTickets(
        selectedMarketProduct.filter((product) => product?.type === "SPORTS")
      );
    }
  }, []);

  /** 선택된 상품 상태 업데이트 시 CheckedList의 상태를 업데이트 */
  useEffect(() => {
    setCheckedList([
      ...selectedEntryTickets,
      ...selectedReservationTickets,
      ...selectedFacilityTickets,
      ...selectedLockerTickets,
      ...selectedSportsTickets,
    ]);
  }, [
    selectedEntryTickets,
    selectedReservationTickets,
    selectedFacilityTickets,
    selectedLockerTickets,
    selectedSportsTickets,
  ]);

  /** 체크박스에 체크된 리스트 정보를 폼에 전달 */
  useEffect(() => {
    if (isNotCreate) {
      if (isSelect) {
        handleSelectedMarketProduct(checkedList);
      }
    } else {
      handleSelectedMarketProduct(checkedList);
    }
  }, [checkedList]);

  /** 상품 탭이 변경되면 filterdProducts 상태를 업데이트 */
  useEffect(() => {
    switch (type) {
      case "LESSON_ENTRY":
        setFilteredProducts(
          privateMarketProducts.filter(
            (product) =>
              product.type === "LESSON" && product.sub_type === "ENTRY"
          )
        );
        break;
      case "LESSON_CLASS":
        setFilteredProducts(
          privateMarketProducts.filter(
            (product) =>
              product.type === "LESSON" && product.sub_type === "CLASS"
          )
        );
        break;
      case "LESSON_FACILITY":
        setFilteredProducts(
          privateMarketProducts.filter(
            (product) =>
              product.type === "LESSON" &&
              (product.sub_type === "FACILITY_GOLF" ||
                product.sub_type === "FACILITY_LIBRARY")
          )
        );
        break;
      case "LOCKER":
        setFilteredProducts(
          privateMarketProducts.filter((product) => product.type === "LOCKER")
        );
        break;
      case "SPORTS":
        setFilteredProducts(
          privateMarketProducts.filter((product) => product.type === "SPORTS")
        );
        break;

      default:
        break;
    }
  }, [type]);

  useEffect(() => {
    let container = containerRef.current;
    if (container) {
      const handleMouseEnter = () => {
        formRef.current.style.overflow = "hidden";
      };
      const handleMouseLeave = () => {
        formRef.current.style.overflow = "auto";
      };
      container.addEventListener("mouseenter", handleMouseEnter);
      container.addEventListener("mouseleave", handleMouseLeave);
      return () => {
        container.removeEventListener("mouseenter", handleMouseEnter);
        container.removeEventListener("mouseleave", handleMouseLeave);
      };
    }
  });


  if (!filterdProducts) return;
  <SpinContainer>
    <Spin size="large" />
  </SpinContainer>;

  return (
    <React.Fragment>
      {filterdProducts && (
        <MenuContainer className="selectProductMenu">
          <Checkbox
            checked={(() => {
              switch (type) {
                case "LESSON_ENTRY":
                  return selectedEntryTickets?.length === entryTickets?.length;
                case "LESSON_CLASS":
                  return (
                    selectedReservationTickets?.length ===
                    reservationTickets?.length
                  );
                case "LESSON_FACILITY":
                  return (
                    selectedFacilityTickets?.length === facilityTickets?.length
                  );
                case "LOCKER":
                  return (
                    selectedLockerTickets?.length === lockerTickets?.length
                  );
                case "SPORTS":
                  return (
                    selectedSportsTickets?.length === sportsTickets?.length
                  );
              }
            })()}
            onChange={handleCheckAll}
            style={{ fontWeight: 700 }}
          >
            전체 선택
          </Checkbox>
          <Divider style={{ border: "1px solid #7c7c7c" }} />
          <div
            style={{
              height: "230px",
            }}
            ref={containerRef}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                overflow: "auto",
              }}
            >
              {filterdProducts?.map((item, index) => {
                const { market_options } = item;
                const price = !market_options
                  ? "-"
                  : market_options?.discounted_price >= 0
                  ? market_options?.discounted_price
                  : market_options?.original_price;
                return (
                  <div
                    key={index}
                    style={{ paddingBottom: "12px", fontSize: "12px" }}
                  >
                    <Item>
                      <Checkbox
                        checked={(() => {
                          switch (type) {
                            case "LESSON_ENTRY":
                              return selectedEntryTickets?.find(
                                (product) => product.key === item.key
                              );
                            case "LESSON_CLASS":
                              return selectedReservationTickets?.find(
                                (product) => product.key === item.key
                              );
                            case "LESSON_FACILITY":
                              return selectedFacilityTickets?.find(
                                (product) => product.key === item.key
                              );
                            case "LOCKER":
                              return selectedLockerTickets?.find(
                                (product) => product.key === item.key
                              );
                            case "SPORTS":
                              return selectedSportsTickets?.find(
                                (product) => product.key === item.key
                              );
                          }
                        })()}
                        onChange={() => {
                          if (!market_options) {
                            setIsProductAddBlocked(true);
                            return;
                          }
                          handleCheckItem(item);
                        }}
                      >
                        <ProductName price={price}>
                        {item.name}
                        </ProductName>
                      </Checkbox>
                      <PriceContainer>
                        <PriceInfo>마켓판매가</PriceInfo>
                        <Spacer size={3} />
                        <Price price={price}>{`${price}원`}</Price>
                      </PriceContainer>
                    </Item>
                  </div>
                );
              })}{" "}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              height: "42px",
              alignItems: "center",
            }}
          >
            <Text>총 {checkedList.length} 건 선택</Text>
          </div>
        </MenuContainer>
      )}
    </React.Fragment>
  );
};

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* Set the height of the component wrapper to be the height of the tabPane minus the height of the tabs */
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PriceContainer = styled.div`
  display: flex;
`;

const PriceInfo = styled.span`
  font-family: "Noto Sans KR-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */

  color: #a4a4a4 !important;
`;

const Price = styled.span.attrs((props) => ({
  style: {
    color: props.price === "-" ? "#a4a4a4" : "#fff",
  },
}))`
  font-family: "Noto Sans KR-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */
`;

const ProductName = styled.span.attrs((props) => ({
  style: {
    color: props.price === "-" ? "#a4a4a4" : "#fff",
  },
}))`
`;

const SpinContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export default SelectPrivateMarketProductView;
